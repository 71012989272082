import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material';
import { Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { notificationType } from 'src/app/shared/notificationType';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private BASE_DURATION = 180;
  private LONG_DURATION = 600000;
  private ACTION = "\u2716" // X-Button
  private SNACKBAR_BASE = 'snack-bar'
  private VERTICAL_POSITION: MatSnackBarVerticalPosition = 'top';
  private EXLCUSIONS: string[] = ["/login"];

  show(type: notificationType, message: string, keepNotificationOpen: boolean = false) {
    let duration: number;
  
    if (keepNotificationOpen || type === notificationType.ERROR) {
      duration = this.getLongDuration();
    } else {
      duration = this.getDuration(message?.length);
    }
  
    this.snackBar.open(message, this.ACTION, {
      duration,
      panelClass: [type, this.SNACKBAR_BASE],
      verticalPosition: this.VERTICAL_POSITION
    });
  }
  
  getDuration(messageLength: number): number {
    return this.BASE_DURATION * messageLength;
  }
  
  getLongDuration(): number {
    return this.LONG_DURATION;
  }
  

  close(){
    this.snackBar?._openedSnackBarRef?.dismiss();
  }

  constructor(private snackBar: MatSnackBar, public router: Router) {
    router.events.pipe(filter((e: any): e is RouterEvent => e instanceof RouterEvent)).subscribe(e => {
      this.EXLCUSIONS.indexOf(e.url) > -1 || this.close()
    });
  }
}

