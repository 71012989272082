<section class="component-wrapper">
    <section class="form-category-wrapper small-margin-bottom" *ngFor="let category of formsContainer | keyvalue">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title [ngClass]="['category', getIconRotationState(category.key)]" (click)="rotateArrowIcon(category.key)">
                    <div class="arrow-box">
                        <div class="arrow" [@rotatedState]="getIconRotationState(category.key)">
                            <svg id="pfeil_unten_mit_kreis" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#083f64;}</style></defs><circle class="cls-1" cx="25" cy="25" r="15.88"/><path class="cls-2" d="M25,27.26l-7.44-7.44a1.53,1.53,0,0,0-2.17,0L14,21.25a1.55,1.55,0,0,0,0,2.18l7.44,7.44,2.52,2.52a1.55,1.55,0,0,0,2.18,0l2.52-2.52,7.44-7.44a1.55,1.55,0,0,0,0-2.18l-1.44-1.43a1.53,1.53,0,0,0-2.17,0Z"/></svg>
                        </div>
                    </div>
                    <div class="text-box">
                        <p>{{ category.key }}</p>
                    </div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <section class="forms-wrapper" *ngFor="let formElement of category.value, index as i">
                    <div class="form-subwrapper">
                        <div class="left-elements" (click)="toView(formElement)">
                            <p>{{ formElement.title }}</p>
                            <p>{{ formElement.info }}</p>
                        </div>
                        <div class="right-elements">
                            <div class="doc-size">
                                <div class="svg-container-doc-type" [innerHTML]="documentService.checkDocumentEnding(formElement.mimeType) | safe"></div>
                                <p>{{ formElement.size | fileSize}} kB</p>
                            </div>
                            <div class="doc-buttons">
                                <a class="doc-view" (click)="toView(formElement)">
                                    <button mat-button >
                                        <img class="img-icon eye" src="assets/media/Auge_w.svg">
                                    </button>
                                </a>
                                <a download (click)="documentService.downloadForm(formElement)">
                                    <button class="doc-download" mat-button>
                                        <img class="img-icon download" src="assets/media/Download_w.svg">
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="doc-buttons">
                        <a class="doc-view" (click)="toView(formElement)">
                            <button mat-button >
                                <img class="img-icon eye" src="assets/media/Auge_w.svg">
                            </button>
                        </a>
                        <a download (click)="documentService.downloadForm(formElement)">
                            <button class="doc-download" mat-button>
                                <img class="img-icon download" src="assets/media/Download_w.svg">
                            </button>
                        </a>
                    </div>
                </section>
            </ng-template>
        </mat-expansion-panel>
    </section>
</section>
