<div class="message-panel">
  <div class="message-panel-header" (click)="goToReplies()">
    <div class="message-panel-title">
      <div class="title-content">
        <div class="title-element-wrapper">
          <div class="title-elements" [ngClass]="message.state">
            <div matBadge="{{ message.commentsUnread }}" [matBadgeOverlap]=false
              [matBadgeHidden]="hasNoMessages(message.commentsUnread)"></div>
            <div class="date">
              <p class="messageDate">{{ message.date | shortDeDate}}</p>
              <div class="status-display">
                <p class="status">
                  {{ messageStatus }}
                </p>
                <img class="img-icon status" src="{{STATUSICON}}" />
              </div>
            </div>
            <div class="message-infos">
              <div class="address">
                <p>{{ message.info1 }}</p>
                <div class="city-postcode">
                  <div class="postcode">
                    <p class="postal-code-location">{{ message.info2 }}</p>
                  </div>
                </div>
                <p>{{ message.info3 }}</p>
              </div>
              <div class="details">
                <p>{{ message.info4 }}</p>
                <p>{{ message.info5 }}</p>
                <p>{{ message.info6 }}</p>
              </div>
              <div class="notifications">
                <p>{{ message.info7 }}</p>
                <p>{{ message.info8 }}</p>
                <p>{{ message.info9 }}</p>
              </div>
              <div class="address-responsive">
                <p>{{ message.info1 }}</p>
                <div class="city-postcode">
                  <div class="postcode">
                    <p>{{ message.info2 }}</p>
                  </div>
                  <div class="city">
                    <p>{{ message.info2 }}</p>
                  </div>
                </div>
                <p>{{ message.info3 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-panel-description">
      <div class="description-elements">
        <div class="msg-buttons">
          <a class="msg-view">
            <button mat-button class="primary">
              <img class="img-icon eye" src="assets/media/Auge_w.svg" />
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="msg-buttons-responsive">
  <button mat-button class="primary" (click)="goToReplies()">
    <p>Anzeigen</p>
  </button>
</div>
