import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

import { MessageAttachment, MimeType } from 'src/app/model/MessageAttachment';
import { ValidationService } from 'src/app/validation.service';
import { Message } from 'src/app/model/Message';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FileExportService } from '../file-export/file-export.service';
@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(private backendService: BackendService, private validationService: ValidationService, private filexportService: FileExportService) { }

  attachment: MessageAttachment;

  getThumbnailForAttachment(message: Message): Observable<MessageAttachment[]> {
    const attachments: Observable<MessageAttachment>[] =  message.attachments.map((a) => {
      a.messageId = message.id;

      if(a.mimeType != MimeType.JPEG) {
        return of(a);
      }

      return this.backendService.getMessageAttachmentThumbnail(this.validationService.getUserToken().supreamGuid, message.id, a.id)
        .pipe(
          catchError((error) => {
            return of(null);
          }),
          map((attachmentData) => {
            if(!attachmentData){
              return null;
            }

            a.file = new Blob([attachmentData], { type:  'image/jpeg' });
            return a;
          })
        )
    });

    return forkJoin(attachments)
    .pipe(
      switchMap(results => {
        const notNullResults = results.filter(result => result !== null);
        return of(notNullResults);
      })
    );
  }

  loadAttachment(attachment: MessageAttachment): Observable<string> {
    return this.backendService.getMessageAttachment(this.validationService.getUserToken().supreamGuid, attachment.messageId, attachment.id);
  }

  setAttachmentToOpen(attachment: MessageAttachment){
    this.attachment = attachment;
  }

  getAttachmentToOpen():MessageAttachment {
    return this.attachment;
  }

  printOpenedAttachment(): void {
    this.filexportService.printFile(this.attachment.file)
  }

  downloadAttachment(attachment: MessageAttachment) {
    this.filexportService.downloadAttachment(attachment);
  }

  downloadOpenedAttachment() {
    this.filexportService.downloadAttachment(this.attachment);
  }
}
