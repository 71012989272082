import { Component, OnInit, ViewEncapsulation, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FileUploadDialogComponent } from "../file-upload-dialog/file-upload-dialog.component";
import { EventEmitter } from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration/configuration.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements OnInit {
  @Input() messageId: string;

  @Output() uploadDialogClosed = new EventEmitter<boolean>();
  // Config Service muss hier schon initialisiert werden, damit im ChildComponent verfügbar
  constructor(public dialog: MatDialog, private configurationService : ConfigurationService) { }

  ngOnInit() { }

  openDialog() {
    const dialogRef = this.dialog.open(FileUploadDialogComponent)
    dialogRef.componentInstance.messageId = this.messageId;
    dialogRef.componentInstance.dialogClosed.subscribe(() => {
      this.uploadDialogClosed.emit(true);
    })
  }

}
