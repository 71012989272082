import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MessageService } from "../../../services/message/message-service.service";
import { MessageType } from "src/app/model/MessageTypes";
import { Contract } from "src/app/model/Contract";

@Component({
  selector: "app-select-message-type",
  templateUrl: "./select-message-type.component.html",
  styleUrls: ["./select-message-type.component.scss"],
})
export class SelectMessageTypeComponent implements OnInit {
  @Input() messageType: MessageType;
  @Input() toggleWhenCreateMessage;
  @Input() contract: Contract;
  @Input() disabled: boolean;
  @Output() onMessageTypeSelected = new EventEmitter<string>();

  constructor(private messageService: MessageService) {}
  isStyleActive: boolean;
  checked: boolean;

  ngOnInit() { }

  ngAfterContentChecked() {
    this.checked = this.getStyleActive();
  }

  ngOnDestroy() {
    this.messageService.selectedMessageTypeForMessage = undefined;
  }

  ngAfterViewChange() {}

  selectMessageType(messageType) {
    if (!this.disabled && !this.checked){
      this.checked = true;
      this.messageService.selectedMessageTypeForMessage = undefined;
      this.messageService.selectedMessageTypeForMessage = messageType.id;

      this.onMessageTypeSelected.emit(messageType);
    }
  }

  getStyleActive(): boolean {
    return (
      this.messageType.id == this.messageService.selectedMessageTypeForMessage
    );
  }

  hideIfNotChecked() {
    return this.toggleWhenCreateMessage || this.checked;
  }
}
