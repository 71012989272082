import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MessageService } from "../../services/message/message-service.service";
import { Contract } from "src/app/model/Contract";

@Component({
  selector: "app-select-agreement",
  templateUrl: "./select-agreement.component.html",
  styleUrls: ["./select-agreement.component.scss"],
})
export class SelectAgreementComponent implements OnInit {
  constructor(private messageService: MessageService) {}

  @Input() contract: Contract;
  @Input() disabled: boolean;
  @Output() onAgreementSelected = new EventEmitter<string>();
  @Input() toggleWhenCreateMessage;

  contracts = new Array<Contract>();
  selectedAgreement: string;
  checked: boolean;

  ngOnInit() {
    this.toggleWhenCreateMessage = true;
    if (this.messageService.preselectedAgreementForMessage && this.messageService.preselectedAgreementForMessage != 'alle Verträge' ) {
      this.selectAgreement(this.contract.contractId)
    }
  }

  ngOnDestroy() {
    this.messageService.selectedAgreementForMessage = undefined;
  }

  ngAfterContentChecked() {
    this.checked = this.getStyleActive();
  }

  getStyleActive(): boolean {
    return (
      this.contract.contractId ==
      this.messageService.selectedAgreementForMessage
    );
  }

  selectAgreement(agreement) {
    if (!this.disabled && !this.checked) {
      this.checked = true;
      this.messageService.selectedAgreementForMessage = undefined;
      this.messageService.selectedAgreementForMessage = agreement.contractId;

      this.onAgreementSelected.emit(agreement);
    }
  }

  hideIfNotChecked() {

    return this.toggleWhenCreateMessage || this.checked;
  }
}
