import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Contract } from "src/app/model/Contract";
import { ConversationService } from "src/app/services/message/conversation-service.service";
import { MessageService } from "src/app/services/message/message-service.service";
import { Message } from "src/app/model/Message";
import { ConversationMessage, Comment } from "src/app/model/ConversationMessage";
import { ErrorService } from "src/app/services/error/error.service";
import { MessageAttachment } from "src/app/model/MessageAttachment";
import { AttachmentsService } from "src/app/services/attachments/attachments.service";

@Component({
  selector: "app-replies",
  templateUrl: "./replies.component.html",
  styleUrls: ["./replies.component.scss"],
})
export class RepliesComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private conversationService: ConversationService,
    private errorService: ErrorService,
    private attachmentService: AttachmentsService
  ) {}
  messages: Array<Message>;
  selectedMessage: Message;
  contractMessage: Message;

  conversations: Array<ConversationMessage>;
  attachments: Array<MessageAttachment>;
  attachmentsWithThumbnail: Array<MessageAttachment>;

  contract: Contract;

  originalMessage: string;
  testoriginalMessage: string;
  replyMessage: string;

  ngOnInit() {
    this.selectedMessage = this.messageService.selectedMessageForReplies;
    this.contract = this.selectedMessage.contract;
    this.conversations = this.selectedMessage.conversation;

    this.conversationService.getAllMessageComments(this.selectedMessage.id).subscribe(comments => {
      this.conversations = JSON.parse(comments);
      this.conversations.forEach(c => c.text = this.parseMessageTextsForDisplay(c.text));
    });

    this.buildThumbnails();
  }

  buildThumbnails(){
    this.conversationService.getOriginalMessage(this.selectedMessage.id).subscribe(message => {
      this.selectedMessage = JSON.parse(message);
      this.originalMessage = this.messageService.buildHTMLMessageDetail(JSON.parse(message));

      this.attachmentService.getThumbnailForAttachment(this.selectedMessage).subscribe(result => {
        if(result){
          this.attachmentsWithThumbnail = result;
        }
      });
    });
  }

  parseMessageTextsForDisplay(messageText: string): string {
    return messageText.replace(/\n/g, '<br>');
  }

  sendReply() {
    if (this.replyMessage) {
      this.conversationService.postMessageComment(this.selectedMessage.id, new Comment({ text: this.replyMessage })).subscribe(result => {
        this.conversationService.getAllMessageComments(this.selectedMessage.id).subscribe(comments => {
          this.conversations = JSON.parse(comments);
          this.conversations.forEach(c => c.text = this.parseMessageTextsForDisplay(c.text));
          this.errorService.getSendCommentSuccessfull();
          this.replyMessage = null;
        });
      }, error => {
        this.errorService.getCommentCouldNotSend();
      });
    }
    else {
      this.errorService.getCommentNotFilled();
    }
  }

  onUploadDialogClosed(){
    this.buildThumbnails();
  }
}
