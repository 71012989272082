import { Injectable } from "@angular/core";
import { Message } from "src/app/model/Message";
import { ConversationMessage, Comment } from "src/app/model/ConversationMessage";
import { BackendService } from "../backend/backend.service";
import { ValidationService } from "src/app/validation.service";
import { Observable, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ConversationService {
  conversation = new ConversationMessage();
  conversation2 = new ConversationMessage();
  conversation3 = new ConversationMessage();
  allMessages = new Array<ConversationMessage>();

  constructor(private backendService: BackendService, private validationService: ValidationService) {

  }

  getAllMessageComments(messageId: string): Observable<any>  {
    return this.backendService.getMessageComments(this.validationService.getUserToken().supreamGuid, messageId);
  }

  getOriginalMessage(messageId: string): Observable<any> {
     return this.backendService.getMessageById(this.validationService.getUserToken().supreamGuid, messageId);
  }

  postMessageComment(messageId: string, comment: Comment): Observable<any>{
    return this.backendService.postMessageComment(this.validationService.getUserToken().supreamGuid, messageId, comment);
  }
}
