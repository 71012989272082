<section class="component-wrapper">
  <section class="form-category-wrapper small-margin-bottom" *ngFor="let category of faqs | keyvalue">
    <header class="component-header">
      <h3>{{ category.key }}</h3>
      <div class="header-bar"></div>
    </header>
    <div *ngFor="let question of category.value, index as i">
      <mat-expansion-panel hideToggle >
        <mat-expansion-panel-header>
          <mat-panel-title [ngClass]="['category', getIconRotationState(category.key, i)]" (click)="rotateArrowIcon(category.key, i)">
            <div class="arrow-box">
              <div class="arrow" [@rotatedState]="getIconRotationState(category.key, i)">
                <svg id="pfeil_unten_mit_kreis" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#083f64;}</style></defs><circle class="cls-1" cx="25" cy="25" r="15.88"/><path class="cls-2" d="M25,27.26l-7.44-7.44a1.53,1.53,0,0,0-2.17,0L14,21.25a1.55,1.55,0,0,0,0,2.18l7.44,7.44,2.52,2.52a1.55,1.55,0,0,0,2.18,0l2.52-2.52,7.44-7.44a1.55,1.55,0,0,0,0-2.18l-1.44-1.43a1.53,1.53,0,0,0-2.17,0Z"/></svg>
              </div>
            </div>
            <div class="json-text text-box" [innerHTML]="question.question | safe"></div>
          </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="json-text faq-forms-wrapper" [innerHTML]="question.answer | safe">
          </div>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </section>
</section>
