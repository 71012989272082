import { Author } from "./ConversationMessage";


export class MessageAttachment {
  id: string;
  fileName: string;
  title: string;
  mimeType: string;
  file: Blob;
  author: Author;
  messageId: string;
  date: Date;
}

export enum MimeType {
  JPEG = 'image/jpeg'
}



