import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Contract } from "../model/Contract";
import { Message } from "../model/Message";
import { MessageService } from "../services/message/message-service.service";
import { StyleService } from "../services/style/style.service";
import { MessageStatusDisplayValues, getMessageState } from "../shared/messageStatus";

@Component({
  selector: "app-message-panel",
  templateUrl: "./message-panel.component.html",
  styleUrls: ["./message-panel.component.scss"],
})
export class MessagePanelComponent implements OnInit {
  @Input() message: Message;
  public messageStatus: string;

  STATUSICON: string;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private styleService: StyleService
  ) {}

  contract: Contract;

  ngOnInit() {
    this.contract = this.message.contract;
    this.messageStatus = getMessageState(this.message);

    this.getStatusIcon();
  }

  hasNoMessages(input) {
    return input < 1;
  }

  goToReplies() {
    this.messageService.selectedMessageForReplies = this.message;
    this.router.navigateByUrl("/replies");
  }

  getStatusIcon() {
    this.styleService.loadStyleConfig().subscribe( styles => {
       this.STATUSICON = this.styleService.getMessageIconPath(styles, this.message);
    });
  }
}
