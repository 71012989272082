import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/model/Form';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FormService } from 'src/app/services/form/form.service';
import { ValidationService } from 'src/app/validation.service';
import { DocumentService } from 'src/app/services/document/document-service.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in'))])]
})

export class FormsComponent implements OnInit {

  formsContainer = new Map<string, Form[]>();

  stateList = new Map<string, string>();
  DEFAULT: string = "default";
  ROTATED: string = "rotated";

  private form: Form;
  private formToShow: Form;

  constructor(private router: Router, public validationService: ValidationService, private formService: FormService, private documentService: DocumentService) {

  }

  async ngOnInit() {
    this.documentService.getForms().subscribe(forms => {
      this.buildContentForExpansionPanel(forms)
    });
  }

  rotateArrowIcon(category: string) {
    let currentState = this.stateList.get(category);
    let newState = (currentState === this.DEFAULT ? this.ROTATED : this.DEFAULT);
    this.stateList.set(category, newState);
  }

  getIconRotationState(category: string) {
    return this.stateList.get(category);
  }

  buildContentForExpansionPanel(forms: Form[]) {
    let categorie = [];
    forms.forEach((form) => {
      if (this.formsContainer.get(form.categoryName)) {
        this.formsContainer.get(form.categoryName).push(form)
      } else {
        categorie.push(form)
        this.formsContainer.set(form.categoryName, categorie)
        categorie = [];
      }
      this.addQuestionToAnimationStateList(form);
    });
  }

  addQuestionToAnimationStateList(form: Form) {
    this.stateList.set(form.categoryName, this.DEFAULT);
  }

  toView(form: Form) {
    this.documentService.setFormToShow(form);
    this.router.navigateByUrl('/document-view');
  }

  setFormToShow(form: Form) {
    this.formToShow = form;
  }


}
