<section class="component-wrapper msg-component-wrapper">
  <form class="filter-container">
    <div class="filter-wrapper">
      <div class="filter-forms">
        <mat-form-field class="agreement-field">
          <mat-label>Vertrag</mat-label>
          <mat-select disableRipple panelClass="panel" name="agreementInput" [disabled]="contractFilterDisabled"
            [(value)]="this.messageSearchFilter.agreementInput" (ngModelChange)="filterAgreement($event)">
            <mat-option [value]="ALLE_VERTRAEGE">alle Verträge</mat-option>
            <mat-option *ngFor="let contractNumber of filterContractNumbers" [value]="contractNumber">{{ contractNumber }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="show-field">
          <mat-label>Status</mat-label>
          <mat-select disableRipple panelClass="panel" name="criteriaInput"
            [(value)]="this.messageSearchFilter.criteriaInput" (ngModelChange)="filterStatus($event)">
            <mat-option value="active">aktiv oder ungelesen</mat-option>
            <mat-option value="all">alle Nachrichten</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button class="send-message primary" color="primary" (click)="createNewMessage()">
        <mat-icon matSuffix class="sendButtonicon">send</mat-icon>
        <p>Neue Nachricht</p>
      </button>
    </div>
  </form>
  <div class="header-content">
    <div class="inbox-wrapper">
      <mat-icon matSuffix class="inboxIcon">inbox</mat-icon>
      <p>Postfach</p>
    </div>
  </div>
    <div class="msg-wrapper" *ngFor="let msg of contractMessages">
      <app-message-panel [message]="msg"></app-message-panel>
    </div>
</section>
