import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { DocumentService } from '../../services/document/document-service.service';
import { Document } from 'src/app/model/Document';
import { Form } from 'src/app/model/Form';
import { StyleService } from 'src/app/services/style/style.service';

declare var Tiff: any; //TODO: TIFF noch nicht lauffähig

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  // Dateipfad
  public url;
  // Datei ist eine PDf
  isPDF: boolean = true;
  // PDF-Seiten
  private page: number = 1;
  private totalPages: number;
  private image;
  numberContainer: number[] = [];

  NAVBUTTONPATH;

  private document: Document;
  private form: Form;

  constructor(
    private backendService: BackendService,
    public validationService: ValidationService,
    private documentService: DocumentService,
    private styleService: StyleService
  ) {
      this.document = this.documentService.getDocumentToShow();
      this.form = this.documentService.getFormToShow();
  }

  ngOnInit() {
    if (this.document) {
      this.backendService.getUserDocumentBinary(this.validationService.getUserToken().supreamGuid, this.document.documentID)
        .subscribe(documentBinaryData => {
          var newBlob = new Blob([documentBinaryData], { type: this.document.mimeType });

          this.setDocumentPropertys(newBlob, documentBinaryData);
        });
    }
    else if (this.form) {
      this.backendService.getUserFormBinary(this.validationService.getUserToken().supreamGuid, this.form.formId)
        .subscribe(documentBinaryData => {
          var newBlob = new Blob([documentBinaryData], { type: this.form.mimeType });
          this.setDocumentPropertys(newBlob, documentBinaryData);
        });
    }
    this.getNavButtonPath();
  }

  setDocumentPropertys(newBlob: Blob, documentBinaryData: any) {
    if (newBlob.type == "application/pdf") {
      this.isPDF = true;
      this.url = URL.createObjectURL(newBlob);
    } else if (newBlob.type == "image/tiff") {
      this.isPDF = false;
      const reader = new FileReader();
      reader.onload = function () {
        var tiff = new Tiff({ buffer: documentBinaryData });
        var canvas = tiff.toCanvas();
      }
    } else {
      this.isPDF = false;
      const reader = new FileReader();
      reader.onload = (e) => this.image = e.target.result;
      reader.readAsDataURL(newBlob);
    }
    this.documentService.fileBinary = newBlob;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    for (let i = 0; i < this.totalPages; i++) {
      this.numberContainer.push(i);
    }
  }

  // Diese Funktion löscht den localstorage und lädt die Seite neu,
  // wenn die File nicht über die URL aufgerufen werden kann.
  onError(error: any) {
    if (error.name = "MissingPDFException") {
      //this.authService.clearLocalstorageSafe();
      window.location.reload();
    }
  }

  // Navigation durch die PDF eine Seite vorwärts oder rückwärts
  nextPage() {
    if (this.page < this.totalPages) {
      this.page++;
    }
  }
  prevPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  // Navigation durch die PDF über direktes Anwählen von Seiten
  changePage(selectedPage: number) {
    this.page = selectedPage;
  }

  getNavButtonPath() {
    this.styleService.loadStyleConfig().subscribe( styles => {
      this.NAVBUTTONPATH = this.styleService.getNavButtonPath(styles);
    });
  }
}
