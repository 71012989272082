import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { Document } from 'src/app/model/Document';
import { Observable, of, Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { async } from '@angular/core/testing';
import { DocumentSearchFilter } from 'src/app/model/DocumentSearchFilter';
import { httpStatusCode } from 'src/app/shared/httpStatusCode';
import { ErrorService } from '../error/error.service';
import { Form } from 'src/app/model/Form';
import { DocumentsComponent } from 'src/app/pages/documents/documents.component';
import { FileExportService } from '../file-export/file-export.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentToShow: Document;
  private formToShow: Form;
  public fileBinary: Blob;
  public filterCategories: Array<string>;
  public filterContractNumbers: Array<string>;
  public documentSearchFilter: DocumentSearchFilter;

  documentToDownload: Document;

  constructor(private backendService: BackendService, public validationService: ValidationService, private errorService: ErrorService, private printanddownloadService: FileExportService) {
    this.initDocumentSearchFilters();
  }

  getDocuments(): Observable<any> {
    let documents = new Subject<Document[]>();
    this.backendService.getUserDocuments(this.validationService.getUserToken().supreamGuid).subscribe(
      documentsData => {
        documents.next(this.parseDateFields(JSON.parse(documentsData)))
      }, error => {
        if (error?.status == httpStatusCode.NOT_FOUND) {
          this.errorService.getNoDocumentsFound();
        }
      }
    );

    return documents.asObservable();
  }

  getForms(): Observable<any> {
    let forms = new Subject<Form[]>();
    this.backendService.getUserForms(this.validationService.getUserToken().supreamGuid).subscribe(
      formsData => {
        forms.next(JSON.parse(formsData))
      });

    return forms.asObservable();
  }

  parseDateFields(documents): any {
    for (var value of documents) {
      value.typedDate = new Date(value.date);
      value.date = formatDate(value.date, "dd.MM.yyyy", "de-DE");

      value.dataSize = Math.ceil(Number(value.size) / 1024);
    }

    return documents
  }

  setSearchInput(value: string) {
    this.documentSearchFilter.searchInput = value;
  }

  initDocumentSearchFilters() {
    this.documentSearchFilter = new DocumentSearchFilter();
  }

  getDocumentSearchFilter(): DocumentSearchFilter {
    return this.documentSearchFilter;
  }

  setAgreementInput(value: string, lockAgreementFilter = false) {
    this.documentSearchFilter.agreementInput = value;
    this.documentSearchFilter.agreementFilterLocked = lockAgreementFilter;
  }

  setDocumentToShow(document: Document) {
    this.documentToShow = document;
    this.formToShow = undefined;
  }

  getDocumentToShow() {
    var mimeType = this.documentToShow?.mimeType;

    if (mimeType != "application/pdf" && mimeType != "image/jpeg" && mimeType != "image/png" && mimeType != "image/tiff") {
      return;
    } else {
      return this.documentToShow;
    }
  }

  setFormToShow(form: Form) {
    this.formToShow = form;
    this.documentToShow = undefined;
  }

  getFormToShow() {
    var mimeType = this.formToShow?.mimeType;

    if (mimeType != "application/pdf" && mimeType != "image/jpeg" && mimeType != "image/png" && mimeType != "image/tiff") {
      return;
    } else {
      return this.formToShow;
    }
  }

  checkDocumentEnding(mType: string) {
    switch (mType) {
      case "application/pdf":
        return '<svg class="doc-type-image-svg" id="datei_pdf" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.21,38.39H15.79a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H26.63a3.87,3.87,0,0,1,1.49.35,3.8,3.8,0,0,1,1.28.84l5.29,5.43a4.32,4.32,0,0,1,.81,1.33,4.2,4.2,0,0,1,.33,1.53V36.71a1.62,1.62,0,0,1-.47,1.18A1.55,1.55,0,0,1,34.21,38.39ZM16.33,13.85v22.3H33.66V22.77h-7a1.54,1.54,0,0,1-1.16-.49A1.64,1.64,0,0,1,25,21.09V13.85ZM27.16,14v6.55h6.37a2,2,0,0,0-.37-.71l-5.3-5.45A1.86,1.86,0,0,0,27.16,14Z"/></g><path class="cls-1" d="M23,30.1a2.86,2.86,0,0,1-.49,1.75,1.61,1.61,0,0,1-1.35.66A1.72,1.72,0,0,1,19.83,32v2.29H19V27.67h.74l0,.53a1.68,1.68,0,0,1,1.38-.62,1.61,1.61,0,0,1,1.35.65A2.83,2.83,0,0,1,23,30ZM22.22,30a2.08,2.08,0,0,0-.34-1.27,1.12,1.12,0,0,0-.94-.47,1.18,1.18,0,0,0-1.11.66V31.2a1.2,1.2,0,0,0,1.12.65,1.1,1.1,0,0,0,.92-.46A2.23,2.23,0,0,0,22.22,30Z"/><path class="cls-1" d="M23.87,30a2.75,2.75,0,0,1,.52-1.76,1.61,1.61,0,0,1,1.35-.67,1.67,1.67,0,0,1,1.33.57V25.68h.81v6.75h-.75l0-.51a1.65,1.65,0,0,1-1.35.59,1.62,1.62,0,0,1-1.35-.67,2.8,2.8,0,0,1-.52-1.77Zm.81.09A2.15,2.15,0,0,0,25,31.37a1.1,1.1,0,0,0,.93.45,1.2,1.2,0,0,0,1.13-.69V28.94A1.22,1.22,0,0,0,26,28.27a1.12,1.12,0,0,0-.94.46A2.37,2.37,0,0,0,24.68,30.1Z"/><path class="cls-1" d="M29.54,32.43V28.3h-.75v-.63h.75v-.49A1.63,1.63,0,0,1,30,26a1.54,1.54,0,0,1,1.16-.42,2.09,2.09,0,0,1,.55.08l0,.65a3,3,0,0,0-.44,0,.81.81,0,0,0-.61.23,1,1,0,0,0-.22.67v.5h1v.63h-1v4.13Z"/></svg>'
      case "application/xls":
        return '<svg class="doc-type-image-svg" id="datei_xls" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.21,38.39H15.79a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H26.63a3.87,3.87,0,0,1,1.49.35,3.8,3.8,0,0,1,1.28.84l5.29,5.43a4.32,4.32,0,0,1,.81,1.33,4.2,4.2,0,0,1,.33,1.53V36.71a1.62,1.62,0,0,1-.47,1.18A1.55,1.55,0,0,1,34.21,38.39ZM16.33,13.85v22.3H33.66V22.77h-7a1.54,1.54,0,0,1-1.16-.49A1.64,1.64,0,0,1,25,21.09V13.85ZM27.16,14v6.55h6.37a2,2,0,0,0-.37-.71l-5.3-5.45A1.86,1.86,0,0,0,27.16,14Z"/></g><path class="cls-1" d="M21.46,29.41l1.06-1.74h.95L21.91,30l1.6,2.4h-.94l-1.09-1.77-1.1,1.77h-.95L21,30l-1.56-2.35h.94Z"/><path class="cls-1" d="M25.21,32.42H24.4V25.67h.81Z"/><path class="cls-1" d="M29.28,31.16a.58.58,0,0,0-.25-.51,2.23,2.23,0,0,0-.86-.31,4.16,4.16,0,0,1-1-.32,1.35,1.35,0,0,1-.54-.44,1,1,0,0,1-.17-.6A1.23,1.23,0,0,1,27,28a1.93,1.93,0,0,1,1.26-.41,2,2,0,0,1,1.32.42,1.34,1.34,0,0,1,.5,1.07h-.82a.72.72,0,0,0-.28-.58,1.08,1.08,0,0,0-.72-.24,1.11,1.11,0,0,0-.69.19.61.61,0,0,0-.25.51.49.49,0,0,0,.23.44,2.91,2.91,0,0,0,.84.29,4.61,4.61,0,0,1,1,.32,1.46,1.46,0,0,1,.56.46,1.13,1.13,0,0,1,.18.65,1.22,1.22,0,0,1-.51,1,2.17,2.17,0,0,1-1.32.38,2.31,2.31,0,0,1-1-.2,1.66,1.66,0,0,1-.69-.56,1.45,1.45,0,0,1-.25-.79h.82a.84.84,0,0,0,.32.65,1.3,1.3,0,0,0,.81.24,1.25,1.25,0,0,0,.74-.19A.56.56,0,0,0,29.28,31.16Z"/></svg>'
      case "image/jpeg":
        return '<svg class="doc-type-image-svg" id="datei_jpg" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.21,38.39H15.79a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H26.63a3.87,3.87,0,0,1,1.49.35,3.8,3.8,0,0,1,1.28.84l5.29,5.43a4.32,4.32,0,0,1,.81,1.33,4.2,4.2,0,0,1,.33,1.53V36.71a1.62,1.62,0,0,1-.47,1.18A1.55,1.55,0,0,1,34.21,38.39ZM16.33,13.85v22.3H33.66V22.77h-7a1.54,1.54,0,0,1-1.16-.49A1.64,1.64,0,0,1,25,21.09V13.85ZM27.16,14v6.55h6.37a2,2,0,0,0-.37-.71l-5.3-5.45A1.86,1.86,0,0,0,27.16,14Z"/></g><path class="cls-1" d="M20.85,27.67V33c0,.92-.42,1.37-1.25,1.37a1.53,1.53,0,0,1-.49-.07v-.65a1.66,1.66,0,0,0,.37,0,.53.53,0,0,0,.41-.15A.71.71,0,0,0,20,33V27.67ZM20,26.41a.48.48,0,0,1,.12-.33.43.43,0,0,1,.36-.14.45.45,0,0,1,.36.14.46.46,0,0,1-.36.79.45.45,0,0,1-.36-.13A.48.48,0,0,1,20,26.41Z"/><path class="cls-1" d="M26.17,30.1a2.86,2.86,0,0,1-.49,1.75,1.61,1.61,0,0,1-1.35.66A1.72,1.72,0,0,1,23,32v2.29h-.81V27.67h.74l0,.53a1.68,1.68,0,0,1,1.38-.62,1.61,1.61,0,0,1,1.35.65,2.83,2.83,0,0,1,.5,1.8ZM25.36,30A2.08,2.08,0,0,0,25,28.74a1.12,1.12,0,0,0-.94-.47,1.19,1.19,0,0,0-1.11.66V31.2a1.2,1.2,0,0,0,1.12.65,1.1,1.1,0,0,0,.92-.46A2.23,2.23,0,0,0,25.36,30Z"/><path class="cls-1" d="M27,30a2.76,2.76,0,0,1,.52-1.77,1.63,1.63,0,0,1,1.36-.66,1.65,1.65,0,0,1,1.36.62l0-.53H31v4.64a1.89,1.89,0,0,1-.55,1.45A2,2,0,0,1,29,34.3a2.5,2.5,0,0,1-1-.22,1.85,1.85,0,0,1-.76-.6l.43-.49a1.56,1.56,0,0,0,1.27.64,1.26,1.26,0,0,0,.93-.33,1.28,1.28,0,0,0,.33-.94V32a1.65,1.65,0,0,1-1.33.56,1.61,1.61,0,0,1-1.35-.67A2.91,2.91,0,0,1,27,30Zm.82.09a2.1,2.1,0,0,0,.33,1.26,1.06,1.06,0,0,0,.92.46,1.19,1.19,0,0,0,1.13-.7V29a1.21,1.21,0,0,0-1.12-.68,1.12,1.12,0,0,0-.93.46A2.37,2.37,0,0,0,27.83,30.1Z"/></svg>'
      case "image/tiff":
        return '<svg class="doc-type-image-svg" id="datei_tif" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.21,38.39H15.79a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H26.63a3.87,3.87,0,0,1,1.49.35,3.8,3.8,0,0,1,1.28.84l5.29,5.43a4.32,4.32,0,0,1,.81,1.33,4.2,4.2,0,0,1,.33,1.53V36.71a1.62,1.62,0,0,1-.47,1.18A1.55,1.55,0,0,1,34.21,38.39ZM16.33,13.85v22.3H33.66V22.77h-7a1.54,1.54,0,0,1-1.16-.49A1.64,1.64,0,0,1,25,21.09V13.85ZM27.16,14v6.55h6.37a2,2,0,0,0-.37-.71l-5.3-5.45A1.86,1.86,0,0,0,27.16,14Z"/></g><path class="cls-1" d="M22.1,26.52v1.15H23v.63H22.1v2.95a.65.65,0,0,0,.12.43.5.5,0,0,0,.41.14,1.58,1.58,0,0,0,.38-.05v.66a2.7,2.7,0,0,1-.62.08,1,1,0,0,1-.82-.33,1.38,1.38,0,0,1-.28-.93V28.3h-.87v-.63h.87V26.52Z"/><path class="cls-1" d="M23.94,26.41a.44.44,0,0,1,.13-.33.42.42,0,0,1,.35-.14.43.43,0,0,1,.36.14.48.48,0,0,1,0,.66.45.45,0,0,1-.36.13.45.45,0,0,1-.48-.46Zm.88,6H24V27.67h.81Z"/><path class="cls-1" d="M26.52,32.42V28.3h-.75v-.63h.75v-.49A1.63,1.63,0,0,1,26.93,26a1.54,1.54,0,0,1,1.16-.42,2.16,2.16,0,0,1,.56.08l0,.65a3,3,0,0,0-.44,0,.81.81,0,0,0-.61.23,1,1,0,0,0-.21.67v.5h1v.63h-1v4.12Z"/></svg>'
      case "image/png":
        return '<svg class="doc-type-image-svg" id="datei_png" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.21,38.39H15.79a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H26.63a3.87,3.87,0,0,1,1.49.35,3.8,3.8,0,0,1,1.28.84l5.29,5.43a4.32,4.32,0,0,1,.81,1.33,4.2,4.2,0,0,1,.33,1.53V36.71a1.62,1.62,0,0,1-.47,1.18A1.55,1.55,0,0,1,34.21,38.39ZM16.33,13.85v22.3H33.66V22.77h-7a1.54,1.54,0,0,1-1.16-.49A1.64,1.64,0,0,1,25,21.09V13.85ZM27.16,14v6.55h6.37a2,2,0,0,0-.37-.71l-5.3-5.45A1.86,1.86,0,0,0,27.16,14Z"/></g><path class="cls-1" d="M21.81,30.1a2.86,2.86,0,0,1-.49,1.75,1.61,1.61,0,0,1-1.35.66A1.72,1.72,0,0,1,18.61,32v2.29H17.8V27.67h.74l0,.53A1.68,1.68,0,0,1,20,27.58a1.61,1.61,0,0,1,1.35.65,2.83,2.83,0,0,1,.5,1.8ZM21,30a2.08,2.08,0,0,0-.34-1.27,1.12,1.12,0,0,0-.94-.47,1.18,1.18,0,0,0-1.11.66V31.2a1.2,1.2,0,0,0,1.12.65,1.11,1.11,0,0,0,.92-.46A2.3,2.3,0,0,0,21,30Z"/><path class="cls-1" d="M23.61,27.67l0,.6a1.73,1.73,0,0,1,1.42-.69c1,0,1.51.57,1.52,1.7v3.15h-.81V29.28a1.1,1.1,0,0,0-.23-.76.94.94,0,0,0-.72-.25,1.18,1.18,0,0,0-.7.21,1.41,1.41,0,0,0-.46.56v3.39h-.82V27.67Z"/><path class="cls-1" d="M27.62,30a2.82,2.82,0,0,1,.51-1.77,1.77,1.77,0,0,1,2.72,0l0-.53h.74v4.64a1.92,1.92,0,0,1-.54,1.45,2,2,0,0,1-1.47.54,2.55,2.55,0,0,1-1-.22,1.76,1.76,0,0,1-.75-.6l.42-.49a1.59,1.59,0,0,0,1.28.64,1.16,1.16,0,0,0,1.26-1.27V32a1.67,1.67,0,0,1-1.34.56,1.63,1.63,0,0,1-1.35-.67A3,3,0,0,1,27.62,30Zm.81.09a2.1,2.1,0,0,0,.33,1.26,1.07,1.07,0,0,0,.93.46,1.19,1.19,0,0,0,1.13-.7V29a1.22,1.22,0,0,0-1.13-.68,1.09,1.09,0,0,0-.92.46A2.36,2.36,0,0,0,28.43,30.1Z"/></svg>'
      default:
        return '<svg class="doc-type-image-svg" version="1.1" id="dokumente" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#003E65;}.st1{fill:#FFFFFF;}.st2{fill:#00A5D7;}</style><g id="Gruppe_218" transform="translate(-58.455 -437)"><path id="ic_content_copy_24px" class="st0" d="M92.62,440H68.91c-2.18,0.01-3.95,1.77-3.95,3.95v27.67h3.95v-27.67h23.71V440z M98.55,447.9H76.81c-2.18,0-3.95,1.77-3.96,3.96v27.67c0.01,2.18,1.77,3.95,3.95,3.95h21.74c2.18-0.01,3.95-1.77,3.95-3.95v-27.67 C102.5,449.67,100.73,447.91,98.55,447.9z M98.55,479.52H76.81v-27.66h21.74V479.52z"/><path id="ic_subject_24px" class="st0" d="M89.46,472.23H78.59v2.84h10.87V472.23z M95.98,460.89H78.59v2.84h17.39V460.89z M78.59,469.4h17.39v-2.84H78.59V469.4z M78.59,455.22v2.84h17.39v-2.84H78.59z"/></g></svg>'
    }
  }

  downloadForm(form: Form) {
    this.printanddownloadService.downloadForm(form);
  }

  printDocument() {
    this.printanddownloadService.printFile(this.fileBinary);
  }

  downloadOpenedDocument() {
    if(this.documentToShow) {
      this.printanddownloadService.downloadDocument(this.documentToShow);
    }
    else if(this.formToShow) {
      this.printanddownloadService.downloadForm(this.formToShow)
    }
  }
}
