import { Component, OnInit } from '@angular/core';
import { MessageAttachment } from 'src/app/model/MessageAttachment';
import { AttachmentsService } from 'src/app/services/attachments/attachments.service';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  attachment: MessageAttachment;
  attachmentFile: Blob;

  constructor(private attachmentService: AttachmentsService) { }

  ngOnInit(): void {
    this.attachment = this.attachmentService.getAttachmentToOpen();
    this.attachmentService.loadAttachment(this.attachment).subscribe(attachmentBinary => {
      this.attachmentFile = new Blob([attachmentBinary], { type:  this.attachment.mimeType });
      this.attachment.file = this.attachmentFile;
    });
  }

}
