<img *ngIf="this.messageAttachment?.mimeType == MimeType.JPEG" [src]="thumbnailUrl"
  alt="Die Datei konnte nicht geladen werden." (click)="triggerDownload()">
<div class="file-icon-wrapper" *ngIf="this.messageAttachment?.mimeType != MimeType.JPEG"
  (click)="triggerDownload()">
  <mat-icon class="file-icon">description</mat-icon>
</div>
<p class="thumbnail-meta">
  <span>{{this.messageAttachment?.title}}</span>
  <span>{{this.messageAttachment?.author.salutation}}</span>
  <span>{{this.messageAttachment?.date | shortDeDate}}</span>
</p>
