import { Component, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { ErrorService } from "src/app/services/error/error.service";
import { BackendService } from "src/app/services/backend/backend.service";
import { ValidationService } from "src/app/validation.service";
import { EventEmitter } from "@angular/core";
import { MimeType } from "src/app/model/MessageAttachment";
import { ConfigurationService } from "src/app/services/configuration/configuration.service";


@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadDialogComponent implements OnInit {
  file: File;
  fileTitle: string;
  filePath: string;
  uploadProgress: number;
  uploadSub: Subscription;
  formData = new FormData();
  myForm: FormGroup;
  public messageId: string;
  uploadIsValid: boolean = false;
  maxFileSize: number;
  fileExtensions: string[];
  validFileExtensionString: string;

  @Output() dialogClosed = new EventEmitter<boolean>();
  MimeType = MimeType;

  constructor(private http: HttpClient,
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private errorService: ErrorService,
    private backendService: BackendService,
    private validationService: ValidationService,
    private configurationService: ConfigurationService,
    public formBuilder: FormBuilder) {
    this.myForm = this.formBuilder.group({
      img: [null]
    })
  }

  ngOnInit() {
    this.validFileExtensionString = this.configurationService.buildFileExtensionsString();
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    this.uploadIsValid = this.configurationService.validateFile(this.file);

    if (this.uploadIsValid) {
      this.myForm.patchValue({
        img: this.file
      });

      this.myForm.get('img').updateValueAndValidity()
      const reader = new FileReader();
      reader.onload = () => {
        this.filePath = reader.result as string;
      }
      reader.readAsDataURL(this.file)
    }
  }

  sendAttachment() {
    if (this.file) {
      if (!this.fileTitle) {
        this.fileTitle = this.file.name;
      }

      let formData = new FormData;
      formData.append(this.fileTitle, this.file)

      return this.backendService.postMessageAttachment(this.validationService.getUserToken().supreamGuid, this.messageId, formData).subscribe(data => {
        this.errorService.getSendAttachmentSuccessfull();
        this.close();
      },
        error => {
          this.errorService.getAttachmentCouldNotSend();
        }
      );
    }

    this.close();
    return false;
  }

  close() {
    this.dialogRef.close();
    this.dialogClosed.emit(true);
  }

  isValid(objectToCheck) {
    return objectToCheck ? true : false;
  }
}

function Ouptut(): (target: FileUploadDialogComponent, propertyKey: "dialogClosed") => void {
  throw new Error("Function not implemented.");
}


