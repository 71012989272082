import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { ErrorService } from "src/app/services/error/error.service";
import { FormFieldsService } from "src/app/services/form-fields/form-fields.service";
import { MessageService } from "src/app/services/message/message-service.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { notificationType } from 'src/app/shared/notificationType';


@Component({
  selector: "app-create-message-form",
  templateUrl: "./create-message-form.component.html",
  styleUrls: ["./create-message-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateMessageFormComponent implements OnInit {

  @Input() messageForm: any;
  @Input() recipient: string;
  @Input() title: any;


  @Output() submitForm = new EventEmitter<string>();
  @Output() submitFormMessageId = new EventEmitter<string>();

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fieldContent: any = {};
  fields: FormlyFieldConfig[];

  fixedInputForm = new FormGroup({});
  fixedInputFields: FormlyFieldConfig[];
  mergedMessageForm: any;
  fixedInputOptions: FormlyFormOptions = {};

  toggleForm: boolean;

  htmlOutput: any;

  constructor(
    public messageService: MessageService,
    private router: Router,
    private formFieldService: FormFieldsService,
    private notificationSerive: NotificationService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.mergedMessageForm = [...this.formFieldService.initFixedInputFields(this.recipient), ...this.messageForm];
  }

  ngOnChange() {}

  goBackToInbox() {
    this.router.navigateByUrl("/messages");
  }

  isValid() {
    return this.form.valid;
  }

  submit() {
    if (this.form.valid) {
      let htmlOutput = this.messageService.buildHTMLMessage(this.mergedMessageForm, this.fieldContent);
      
      this.messageService.submitForm(htmlOutput, this.fieldContent).subscribe(messageId => {
        this.submitFormMessageId.emit(messageId);
        this.submitForm.emit(htmlOutput);
      });
      
      this.toggleForm = !this.toggleForm;
    } else {
      this.errorService.getRequiredFormFieldsNotFilled();
    }
  }
}
