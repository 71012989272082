import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { FAQ } from 'src/app/model/FAQ';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in'))])]
})
export class FaqComponent {
  faqs = new Map<string, FAQ[]>();

  stateList = new Map<string, string>();
  DEFAULT: string = "default";
  ROTATED: string = "rotated";

  constructor(private backendService: BackendService, private validationService: ValidationService) { }

  async ngOnInit() {
    this.backendService.getUserFAQs(this.validationService.getUserToken().supreamGuid).subscribe(faqs => {
      this.buildContentForExpansionPanel(JSON.parse(faqs.body));
    });
  }

  rotateArrowIcon(category: string, index: number) {
    let currentState = this.stateList.get([category, index].join(','));
    let newState = (currentState === this.DEFAULT ? this.ROTATED : this.DEFAULT);
    this.stateList.set([category, index].join(','), newState);
  }

  getIconRotationState(category: string, index: number) {
    return this.stateList.get([category, index].join(','));
  }

  buildContentForExpansionPanel(questions: FAQ[]) {
    let categorie = [];
    questions.forEach((question) => {
      if (this.faqs.get(question.categoryName)) {
        this.faqs.get(question.categoryName).push(question)
      } else {
        categorie.push(question)
        this.faqs.set(question.categoryName, categorie)
        categorie = [];
      }
      this.addQuestionToAnimationStateList(question);
    });
  }

  addQuestionToAnimationStateList(faq: FAQ){
    this.stateList.set([faq.categoryName, this.faqs.get(faq.categoryName).length - 1].join(','), this.DEFAULT);
  }
}
