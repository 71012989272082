import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Document } from 'src/app/model/Document';
import { DocumentService } from 'src/app/services/document/document-service.service';
import { FileExportService } from '../services/file-export/file-export.service';

@Component({
  selector: 'app-document-panel',
  templateUrl: './document-panel.component.html',
  styleUrls: ['./document-panel.component.scss']
})
export class DocumentPanelComponent implements OnInit {
  @Input() document: Document;

  constructor(private router: Router, public documentService: DocumentService, private fileExportService: FileExportService) { }

  ngOnInit(): void {
  }

  // öffnet die Dokumentansicht
  toView() {
    this.documentService.setDocumentToShow(this.document);
    this.router.navigateByUrl('/document-view');
  }

  // ermöglicht den Download eines Dokuments
  download(){
    this.fileExportService.downloadDocument(this.document);
  }
}
