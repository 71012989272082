<div class="file-upload-dialog-wrapper">
  <div class="dialog-header">
    <p class="dialog-title">Anlage hinzufügen</p>
    <button mat-icon-button class="close-dialog" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <input type="file" class="file-input" [accept]="validFileExtensionString" (change)="onFileSelected($event)">
  <div class="change-file-name" *ngIf="this.uploadIsValid">
    <div class="header-wrapper">
      <p class="header">Möchten Sie der Datei einen Titel geben?</p>
    </div>
    <div class="file-wrapper">
      <div class="file-preview">
        <img *ngIf="this.file.type == MimeType.JPEG" [src]="filePath" [alt]="myForm.value.name">
        <div class="file-icon-wrapper" *ngIf="this.file.type != MimeType.JPEG">
          <mat-icon class="file-icon">description</mat-icon>
        </div>
      </div>
      <div class="rename-file">
        <mat-form-field class="rename-file-form-field">
          <mat-label>Titel</mat-label>
          <input matInput type="text" name="input-file-name" [(ngModel)]="fileTitle">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="send-button-wrapper">
    <button mat-button class="send-button primary" [class.inactive]="!this.uploadIsValid" [disabled]="!this.uploadIsValid"
      (click)="this.uploadIsValid ? sendAttachment() : 'false'">Senden
      <mat-icon matSuffix>send</mat-icon>
    </button>
  </div>
</div>
