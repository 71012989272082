import {DOCUMENT, ɵgetDOM as getDOM} from '@angular/common';
import {Inject, Injectable, ɵɵinject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { MessageStatus } from 'src/app/shared/messageStatus';
import { Message } from 'src/app/model/Message';

// import custom_stylesheet from '../../../assets/custom_styles/custom_styles.json';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  constructor(
    @Inject(DOCUMENT) private _doc: any, private http: HttpClient) { }

  FAVICONSELECTOR: string = '#favIcon';
  LOGOIMAGESELECTOR: string = '#logo';
  PRIMARYCOLOR: string = "#00A5D7";
  NAVBUTTONPATH: string = "assets/media/Pfeil_unten_w.svg";
  AKTIVICONPATH: string = "assets/media/icon-status-aktiv.svg";
  UNREADICONPATH: string = "assets/media/icon-status-ungelesen.svg";
  INAKTIVICONPATH: string = "assets/media/icon-status-beendet.svg";
  WELCOMETEXT: string = "Anmeldung im Mieterportal";
  HOMEPAGETEXT: string = "Willkommen im Mieterportal";
  REGISTERTEXT: string = "Registrierung im Mieterportal";
  TITLETEXT: string = "Mieterportal";

  applyCustomStyle(styles) {
    if(styles){
      this.applyCustomStylesheet(styles[0].css);
      this.applyCustomTitle(styles[0].title);
      this.applyCustomFavicon(styles[0].favicon);
      this.applyCustomLogoImage(styles[0].logo);
    }
  }

  loadStyleConfig(): Observable<any> {
    let config = new Subject<any>();
    this.http.get('../../assets/custom_styles/custom_styles.json')
      .subscribe(data => {
        this.applyCustomStyle(data);
        config.next(data);
      });
    return config.asObservable();
  }

  applyCustomStylesheet(styles?: string){
    const head = this._doc.getElementsByTagName('head')[0];
    const style = this._doc.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    head.appendChild(style);
  }

  applyCustomTitle(title?: string) {
    this._doc.title = title || this.TITLETEXT;
  }

  applyCustomFavicon(iconPath?: string){
    let favIcon = this._doc.querySelector(this.FAVICONSELECTOR);
    if(iconPath) {
      favIcon.href = iconPath;
    }
  }

  applyCustomLogoImage(imagePath?: string){
    let image = this._doc.querySelector(this.LOGOIMAGESELECTOR);
    if(imagePath) {
      image.src = imagePath;
    }
  }

  getWelcomeText(styles): string {
    return styles[0]?.welcomeText || this.WELCOMETEXT;
  }

  getHomepageText(styles): string {
    return styles[0]?.homepageText || this.HOMEPAGETEXT;
  }

  getRegisterText(styles): string {
    return styles[0]?.registerText || this.REGISTERTEXT;
  }

  getPrimaryColor(styles) {
    return styles[0]?.primarycolor || this.PRIMARYCOLOR;
  }

  getNavButtonPath(styles) {
    return styles[0]?.navbuttons || this.NAVBUTTONPATH;
  }

  getheaderHTML(styles) {
    return styles[0]?.headerHTML || "";
  }

  getMessageIconPath(styles, message: Message) {
    switch (true) {
      case message.commentsUnread > 0:
        return styles[0]?.unreadIcon || this.UNREADICONPATH;
      case message.state == MessageStatus.active:
        return styles[0]?.aktivIcon || this.AKTIVICONPATH;
      default :
        return styles[0]?.inaktivIcon || this.INAKTIVICONPATH;
    }
  }
}
