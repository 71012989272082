import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { Document } from 'src/app/model/Document';
import * as FileSaver from 'file-saver';
import { MessageAttachment } from 'src/app/model/MessageAttachment';
import { Form } from 'src/app/model/Form';

@Injectable({
  providedIn: 'root'
})
export class FileExportService {

  constructor(private backendService: BackendService, public validationService: ValidationService) { }

  iframe: any;

  // Works for documents and attachements
  printFile(blob: Blob) {
    this.removeIFrame(this.iframe); // here so they dont stack up and max one at a time
    const blobUrl = URL.createObjectURL(blob);

    let iframe = document.createElement('iframe');
    this.iframe = iframe;
    iframe.className = 'pdfIframe';
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
        URL.revokeObjectURL(blobUrl)
      }, 2000);
    };
    iframe.src = blobUrl;
  }

  removeIFrame(iframe: any) {
    if(iframe) {
      document.body.removeChild(this.iframe);
    }
  }

  // Documents
  downloadDocument(document: Document) {
    this.backendService.getUserDocumentBinary(this.validationService.getUserToken().supreamGuid, document.documentID)
      .subscribe(documentBinaryData => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([documentBinaryData], { type: document.mimeType });
        FileSaver.saveAs(newBlob, document.mimeType);
      });
  }

  // Attachements
  downloadAttachment(attachment: MessageAttachment) {
    return this.backendService.getMessageAttachment(this.validationService.getUserToken().supreamGuid, attachment.messageId, attachment.id)
      .subscribe(attachmentBinary => {
        var newBlob = new Blob([attachmentBinary], { type:  attachment.mimeType });
        FileSaver.saveAs(newBlob, attachment.mimeType);
      });
  }

  // Forms
  downloadForm(form: Form) {
    this.backendService.getUserFormBinary(this.validationService.getUserToken().supreamGuid, form.formId)
      .subscribe(formBinaryData => {
        var newBlob = new Blob([formBinaryData], { type: form.mimeType });
        FileSaver.saveAs(newBlob, form.mimeType);
      });
  }
}
