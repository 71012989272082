import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { httpStatusCode } from 'src/app/shared/httpStatusCode';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidationService } from 'src/app/validation.service';
import { ErrorService } from '../error/error.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private authService: AuthService, private fb: FormBuilder, private errorService: ErrorService, private router: Router) { }

  changeUserPassword(email: string, newPassword: string, oldPassword: string): Observable<boolean> {
    var responseMessage = new Subject<boolean>();

    this.authService.changeUserPassword(email, newPassword, oldPassword).subscribe(response => {
      if (response.status == httpStatusCode.OK) {
        this.errorService.getChangePasswordSuccessfull();
        responseMessage.next(true);
      }
    }, err => {
      if (err.status == httpStatusCode.UNAUTHORIZED) {
        this.errorService.getResetWrongPasswordError();        
      }
      else {
        this.errorService.getResetPasswordError();
      }
    });

    return responseMessage.asObservable();
  }

  resetUserPassword(email: string, newPassword: string): Observable<string> {
    var responseMessage = new Subject<string>();

    this.authService.resetUserPassword(email, newPassword).subscribe(response => {
      if (response.status == httpStatusCode.NO_CONTENT) {
        this.errorService.getChangePasswordSuccessfull();
        this.router.navigate(["/homepage"]);
      }
    }, err => {
      if (err.status == httpStatusCode.UNAUTHORIZED) {
        this.errorService.getResetWrongPasswordError();
      }
      else {
        this.errorService.getResetPasswordError();
      }
    });

    return responseMessage.asObservable();
  }

  getErrorMessagePasswordOrTokenInvalid(){
    this.errorService.getResetPasswordInvalid();
  }

  validateResetToken(mail: string, resetToken: string): Observable<boolean>{
    var responseMessage = new Subject<boolean>();
    this.authService.approvePasswordResetToken(mail, resetToken).subscribe(isValid => {
      if(isValid.status == httpStatusCode.NO_CONTENT){
        responseMessage.next(true);
      } else {
        responseMessage.next(false)
      }
    },
    error => {
      responseMessage.next(false);
    });

    return responseMessage.asObservable();
  }

  createPasswordForm(): FormGroup {
    return this.fb.group(
      {
        password: [null, Validators.compose([
          // required
          Validators.required,
          // Zahl vorhanden
          ValidationService.patternValidator(/\d/, { hasNumber: true }),
          // Großbuchstabe vorhanden
          ValidationService.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // Kleinbuchstabe vorhanden
          ValidationService.patternValidator(/[a-z]/, { hasSmallCase: true }),
          // Sonderzeichen vorhanden
          ValidationService.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
          // minimale Länge von 8 Zeichen eingehalten
          Validators.minLength(8)])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // Vergleich der Passworteingaben
        validator: ValidationService.passwordMatchValidator
      });
  }
}
