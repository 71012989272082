export class Document {
    documentID: string;
	mimeType: string;
	date: string;
	size: string;
	contractId: string;
	contractNumber: string;
	contractSelectField: string;
	filename: string;
	category: string;
	info1: string;
	info2: string;
	info3: string;
	info4: string;
	unread: boolean;

	typedDate: Date;
	dataSize: number;
}