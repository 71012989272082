<div class="contact-wrapper">
  <p class="contact-top-line">Ihr Ansprechpartner für Rückfragen</p>
  <div class="contact">
    <div class="contact-content">
      <div class="contact-icon">
        <span class="material-icons">person</span>
      </div>
      <div class="contact-infos">
        <p class="contact-title">{{ contact.fullName }} </p>
        <div class="contact-info-details">
          <p class="contact-description">{{ contact.mail }}</p>
          <p class="contact-description">{{ contact.company.cpyName }}</p>
          <p class="contact-description">{{ contact.company.streetAndNo + " " + contact.company.postcode + " " + contact.company.cityName }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
