import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserToken } from './model/userToken';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  // GENERELL
  private boolContractNumber: boolean = false;

  // LOGIN
  private validLogin: boolean = false;
  private email: string = "";
  private password: string = "";
  private confirmPassword: string = "";
  private userToken: UserToken;

  // REGISTRIERUNG
  private identification: string = "";
  private registrationCode: string = "";
  private terms: boolean = false;
  private termsVersion: string = "";
  private contractNumber: string = "";
  private verificationCode: string = "";
  private noPaper: boolean = false;
  private showNoPaperHTML: boolean = false;

  public token: string;

  private guid: string = "";

  constructor(private router: Router) { }

  // GETTER UND SETTER
  setShowNoPaperHTML(noPaper: boolean){
    this.showNoPaperHTML = noPaper;
  }

  getShowNoPaperHTML(): boolean {
    return this.showNoPaperHTML;
  }

  setAgreedTermsVersion(version: string){
    this.termsVersion = version;
  }

  getAgreedTermsVersion(): string {
    return this.termsVersion;
  }

  setIfIsContractNumber(value: boolean) {
    this.boolContractNumber = value;
  }
  getIfIsContractNumber() {
    return this.boolContractNumber;
  }

  setLoginValidation(value: boolean) {
    this.validLogin = value;
  }
  getLoginValidation() {
    return this.validLogin;
  }

  setGuid(value: string) {
    this.guid = value;
  }

  getGuid(){
    return this.guid;
  }

  // Die Werte der Registrierung werden im localStorage hinterlegt,
  // um beim Neuladne der Seite noch zur Verfügung zu stehen und
  // werden von den Gettern eingelesen falls vorhanden.

  // start
  setIdentification(value: string) {
    this.identification = value;
  }
  getIdentification() {
    return this.identification;
  }

  setRegistrationCode(value: string) {
    this.registrationCode = value;
  }
  getRegistrationCode() {
    return this.registrationCode;
  }

  setTerms(value: boolean) {
    this.terms = value;
  }

  getTerms() {
    return this.terms;
  }

  // step 2 (contract number)
  setContract(value: string) {
    this.contractNumber = value;
  }
  getContract() {
    return this.contractNumber;
  }

  // step 3 (e-mail)
  setEmail(value: string) {
    this.email = value;
  }
  getEmail() {
    return this.email;
  }

  setVerificationCode(value: string) {
    this.verificationCode = value;
  }
  getVerificationCode() {
    return this.verificationCode;
  }

  setNoPaper(value: boolean) {
    this.noPaper = value;
  }
  getNoPaper() {
    return this.noPaper;
  }

  // step 5 (password)
  setPassword(value: string) {
    this.password = value;
  }

  getPassword() {
    return this.password;
  }

  setConfirmPassword(value: string) {
    this.confirmPassword = value;
  }

  getConfirmPassword() {
    return this.confirmPassword;
  }

  setUserToken(tokenString: string) {
    var decodedToken = jwt_decode(JSON.parse(tokenString).access_token);
    this.buildUserToken(JSON.stringify(decodedToken));
    this.token = JSON.parse(tokenString).access_token;
  }

  getUserToken() {
    return this.userToken;
  }

  private buildUserToken(tokenString){
    this.userToken = new UserToken();
    var token = JSON.parse(tokenString);

    this.userToken.id_token = token["sub"];
    this.userToken.expire = token["exp"];
    this.userToken.supreamGuid = token["extension_SupreamGUID"];
  }

  deleteUserToken(){
    this.userToken = undefined;
  }



  // FUNKTIONALITÄT
  changeProgress(valid: boolean) {
    let step: number = this.calcStep();
    localStorage.setItem("reg" + (step), valid ? "true" : "false");

    if(!valid) {
      return false;
    }

    // Setzen der Vertragsnummer überspringen, falls das nicht erforderlich ist
    if (!this.boolContractNumber && step == 1) {
      step += 1;
    }

    this.router.navigate(["/registration/" + (step + 1)]);

    return true;
  }

  // in der Registrierung einen Schritt zurückgehen (zurück-Button)
  stepBack() {
    let step: number = this.calcStep();
    if (step > 1) {
      if (!this.boolContractNumber && step == 3) {
        step -= 1;
      }
      this.router.navigate(["/registration/" + (step - 1)]);
    } else {
      this.router.navigate(["/registration"]);
    }
  }

  // die Nummer des Registrierungsschrittes ermitteln
  calcStep(): number {
    let step: string = this.router.url.slice(-1);
    if (step == "n") { // erste Eingabe vor dem eigentlichen ersten Registrierungsschritt
      return 0;
    }
    return +step;
  }

  // Abbruch der Registrierung
  cancelRegistration() {
    this.router.navigate(["/homepage"]);
  }

  // Logindaten löschen
  deleteCredentials() {
    this.email = "";
    this.password = "";
  }

  // abfragen, ob der aktuelle Registrierungsschritt erreichbar ist
  checkRegistrationStep(step: number) {
    return (step === 3 && !this.getIfIsContractNumber() && localStorage.getItem("reg1") === "true") // dritter Reg-schritt ist abhängig von der Vertragsnummer
           || localStorage.getItem("reg" + (step - 1)) === "true"; // Reg-schritt erreichbar, wenn der Vorgänger erledigt ist
  }

  // überprüft, ob ein bestimmter String als Substring in einem Array von Strings vorkommt
  isElementOf(array: string[], link: string) {
    for(let i = 0; i < array.length; i++) {
      if (array[i].indexOf(link) != -1) {
        return true;
      }
    }

    return false;
  }

  // Pattern-Validierung
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      // Regex-Pattern gegen den Value testen
      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  // überprüfe, ob newPassword und confirmPassword übereinstimmen
  static passwordMatchValidator(control: AbstractControl) {
    // Passworteingaben über den Controler holen
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }

    /**
   * Resets the variables used in the registration process.
   * User-Token will not be reset
   */
  resetRegistration(){
    this.setEmail(undefined);
    this.setPassword(undefined);
    this.setIdentification(undefined);
    this.setRegistrationCode(undefined);
    this.setTerms(false);
    this.setContract(undefined);
    this.setVerificationCode(undefined);
    this.setNoPaper(false);
  }
}
