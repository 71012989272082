import { Component } from "@angular/core";
import { MessageService } from "src/app/services/message/message-service.service";
import { Router } from "@angular/router";
import { DocumentSearchFilter } from "src/app/model/DocumentSearchFilter";
import { MessageStatus } from "src/app/shared/messageStatus";
import { Contract } from "src/app/model/Contract";
import { Message } from "src/app/model/Message";

@Component({
  selector: "messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent {
  contracts = new Array<Contract>();
  allContracts = new Array<Contract>();
  contractMessages = new Array<Message>();
  allMessages = new Array<Message>();
  messageSearchFilter = new DocumentSearchFilter();

  filterContractNumbers;
  filterStatusTypes;

  contractFilterDisabled = false;

  readonly ALLE_VERTRAEGE: string = "alle Verträge";
  readonly OHNE_VERTRAGSBEZUG = "ohne Vertragsbezug"

  constructor(public messageService: MessageService, private router: Router) {}

  ngOnInit() {
    this.loadFilters();

    this.messageService.getAllMessages().subscribe(
      messages => {
        this.contractMessages = JSON.parse(messages)

        this.contractMessages.sort((a, b) => b.commentsUnread - a.commentsUnread);
        this.allMessages = this.contractMessages;

        this.createFilterContractNumbers();
        this.evaluate;
      }
    );
  }

  loadFilters() {
    this.messageSearchFilter = this.messageService.getMessageSearchFilter();

    this.messageSearchFilter.agreementInput = this.messageSearchFilter.agreementInput || this.ALLE_VERTRAEGE;
    this.messageSearchFilter.criteriaInput = this.messageSearchFilter.criteriaInput || MessageStatus.active ;
    this.contractFilterDisabled = this.messageSearchFilter.agreementFilterLocked;
  }

  createFilterContractNumbers() {
    this.filterContractNumbers = new Array<string>();

    for (let message of this.allMessages) {
      if (!this.filterContractNumbers.includes(message.info3)) {
        if (message.info3) {
          this.filterContractNumbers.push(message.info3);
        }
        // wenn der zugeordnete Vertrag leer ist (""), muss der Filter um "Ohne Vertragsbezug" erweitert werden.
        else if (!this.filterContractNumbers.includes(this.OHNE_VERTRAGSBEZUG)) {
          this.filterContractNumbers.push(this.OHNE_VERTRAGSBEZUG);
        }
      }
    }
  }

  filterAgreement(input) {
    if (input) {
      this.messageSearchFilter.agreementInput = input.value;
      this.evaluate();
    }
  }

  filterStatus(input) {
    if (input) {
      this.messageSearchFilter.criteriaInput = input.value;
      this.evaluate();
    }
  }

  ngOnDestroy() {
    const selectedFilterSubPageTitles = ["messages", "attachments", "replies"]

    if (!selectedFilterSubPageTitles.some(exep => this.router.url.match(exep))) {
      this.messageService.initDocumentSearchFilters();
    } 
  }

  ngAfterViewChecked() {
    this.evaluate();
  }

  evaluate() {
    this.contractMessages = this.allMessages;

    // Vertragsfeld
    if (this.messageSearchFilter.agreementInput == this.ALLE_VERTRAEGE) {
      this.contractMessages = this.allMessages;
    }
    else if (this.messageSearchFilter.agreementInput == this.OHNE_VERTRAGSBEZUG) {
      this.contractMessages = this.contractMessages?.filter((msg) => {
        return !msg.info3;
      });
    }
    else if (this.messageSearchFilter.agreementInput) {
      this.contractMessages = this.contractMessages?.filter((msg) => {
        return msg.info3
          .toLowerCase()
          .match(this.messageSearchFilter.agreementInput.toLowerCase());
      });
    }

    // Bei criteriaInput active ist effektiv active und unread gemeint
    if (this.messageSearchFilter.criteriaInput === MessageStatus.active) {
      this.contractMessages = this.contractMessages?.filter((msg) => {
        return this.messageIsActiveOrUnread(msg);
      });
    } else {
      return this.contractMessages;
    }
  }

  private messageIsActiveOrUnread(message: Message){
    return MessageStatus[message.state].toLowerCase() === MessageStatus.active
    || message.commentsUnread > 0;
  }

  createNewMessage() {
    if (this.messageSearchFilter.agreementInput) {
      this.messageService.preselectedAgreementForMessage = this.messageSearchFilter.agreementInput;
    }
    this.router.navigateByUrl("/new-message");
  }
}
