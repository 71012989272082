<section *ngFor="let contract of contracts" class="contact-wrapper">
    <div class="agreement-info-wrapper">
        <div class="agreement-info">
            <p>Vertragsnummer: {{ contract.number }}</p>
        </div>
        <div class="agreement-info">
            <div>
                <p>{{ contract.unitStreetAndNo }}</p>
                <p>{{ contract.unitPostcode + " " + contract.unitCity }}</p>
            </div>
        </div>
        <div class="agreement-info">
            <div>
                <p>{{ contract.unitInfo1 }}</p>
                <p>{{ contract.unitInfo2 }}</p>
            </div>
        </div>
    </div>
    <div *ngFor="let contact of contract.contactPersons">
        <div class="contact-info-wrapper">
            <div class="contact-icon">
                <svg  version="1.1" id="Mein_Account" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#003E65;}.st1{fill:#FFFFFF;}.st2{fill:#00A5D7;}</style><path id="ic_person_24px_1_" class="st0" d="M24.5,25c4.97,0,9-4.03,9-9s-4.03-9-9-9s-9,4.03-9,9S19.53,25,24.5,25z M24.5,29.5 c-6.01,0-18,3.01-18,9V43h36v-4.5C42.5,32.51,30.51,29.5,24.5,29.5z"/></svg>
            </div>
            <div class="contact-info">
                <div>
                    <p>{{ contact.role }}</p>
                    <p>{{ contact.salutation }}</p>
                </div>
                <div>
                    <a *ngIf="contact.phone" href="tel:{{ contact.phone }}">{{ contact.phone }}</a>
                    <a *ngIf="contact.mobile" href="tel:{{ contact.mobile }}">{{ contact.mobile }}</a>
                    <a *ngIf="contact.mail" href="mailto:{{ contact.mail }}">{{ contact.mail }}</a>
                </div>
                <div>
                    <p>{{ contact.company }}</p>
                    <p>{{ contact.streetAndNo + " - " + contact.postcode + " " + contact.city }}</p>
                </div>
            </div>
            <button mat-button class="contact-message-big" *ngIf="contact.type == 'Verwalter'" (click)="message(contact.name)">
                <img src="../assets/media/senden_neu.svg">
            </button>
        </div>
        <div class="message-button-box">
            <button mat-button class="contact-message-small" *ngIf="contact.type == 'Verwalter'" (click)="message(contact.name)">
                <img src="../assets/media/senden_neu.svg">
            </button>
        </div>
    </div>
</section>
