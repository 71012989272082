export class ConversationMessage {
  date: string;
  text: string;
  author: Author;

}

export class Author {
  salutation: string;
  authorType: string;
}

export class Comment {
  text: string;

  constructor(data: Comment) {
    Object.assign(this, data);
  }
}
