import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/validation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  emailAdress: string = "";
  password: string = "";
  hiddenPassword: string;

  constructor(private authService: AuthService, private router: Router, private validationService: ValidationService){

  }

  submitLoginByKeypress(event, valid: boolean) {
    if(event.key === "Enter") {
      this.login(valid);
    }
  }

  // die Nutzerdaten überprüfen und anmelden
  async login(valid: boolean) {

    if (valid) {
      this.validationService.setEmail(this.emailAdress);
      this.validationService.setPassword(this.hiddenPassword);

      this.authService.doLogin().subscribe(() => this.router.navigate(["/homepage"]));
    }
  }

  loginIsValid(formValues: any): boolean {
    return formValues.value.email != undefined && formValues.value.email != '' && formValues.valid && formValues.value.password != '';
  }
}
