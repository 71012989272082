import { Injectable } from '@angular/core';
import { getMatFormFieldMissingControlError } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { Form } from 'src/app/model/Form';
import { ValidationService } from 'src/app/validation.service';
import { BackendService } from '../backend/backend.service';
import * as FileSaver from 'file-saver';
import { DocumentService } from '../document/document-service.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private formToShow: Form;


  constructor(private backendService: BackendService, public validationService: ValidationService) {

   }

   setFormToShow(form: Form) {
    this.formToShow = form;
  }

   getFormToShow() {
    var mimeType = this.formToShow?.mimeType;

    if (mimeType != "application/pdf" && mimeType != "image/jpeg" && mimeType != "image/png" && mimeType != "image/tiff") {
      return;
    } else {
      return this.formToShow;
    }
  }
}
