<section class="view-header">
    <div class="page-wrapper">
        <div class="single-page" *ngFor="let index of numberContainer;" (click)="changePage(index + 1)">
            <div [class.pdf-border]="(index + 1) == page">
                <pdf-viewer class="pdf-navigation" src="{{url}}"
                    [page]="(index + 1)"
                    [show-all]="false"
                    [render-text]="false"
                    [original-size]="false"
                    [fit-to-page]="true"
                    [autoresize]="true"
                ></pdf-viewer>
            </div>
            <p>{{ index + 1 }}/{{ totalPages }}</p>
        </div>
    </div>
</section>
<section class="component-wrapper" *ngIf="isPDF; else elseBlock">
    <div class="navigation-buttons">
        <div class="back-button" (click)="prevPage()">
            <img src="{{NAVBUTTONPATH}}">
        </div>
        <div class="forward-button" (click)="nextPage()">
            <img src="{{NAVBUTTONPATH}}">
        </div>
    </div>
    <div class="document-view">
        <pdf-viewer class="pdf-view" src="{{url}}"
            [page]="page"
            [show-all]="false"
            [render-text]="false"
            [original-size]="false"
            [fit-to-page]="true"
            [autoresize]="true"
            (after-load-complete)="afterLoadComplete($event)"
            (error)="onError($event)"
        ></pdf-viewer>
    </div>
</section>
<ng-template #elseBlock>
    <section class="other-file-wrapper" *ngIf="url != ''; else elseBlock">
        <img src="{{image}}"  alt="Die Datei konnte nicht geladen werden.">
    </section>
    <ng-template #elseBlock>
        <p style="text-align: center;">Beim Laden des Dokuments ist ein Fehler aufgetreten.</p>
    </ng-template>
</ng-template>
