import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
})
export class TimeInputComponent extends FieldType {

  validTimeReg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  onBlur() {
    const value = this.formControl.value;
    var convertedValue = this.formatToHHMM(value);

    // no error message for leaving it blank
    if (!value) {
      return; // do nothing...
    }

    if (!this.validTimeReg.test(convertedValue)) {
      this.formControl.setErrors({ invalidTimeFormat: true });
    } else {
      this.formControl.setErrors(null);
      this.formControl.setValue(convertedValue);
    }
  }

  formatToHHMM(value: string): string {
    switch (true) { // switch true means only cases which are true getting handled
      case value === null:
        return null;
      case value.includes(':') && value.length === 4 && parseInt(value[0]) < 10:
        return '0' + value;
      case value.length === 4:
        return value.replace(/(\d{2})(\d{2})/, "$1:$2");
      case value.length === 2 && parseInt(value) < 24:
        return value + ":00";
      case value.length === 1 && parseInt(value) < 10:
        return '0' + value + ":00";
      case this.validTimeReg.test(value):
        return value;
      default:
        return null;
    }
  }
}
