import { Component, Input, OnInit } from "@angular/core";
import { Contact } from "../model/Contact";

@Component({
  selector: "app-contact-panel",
  templateUrl: "./contact-panel.component.html",
  styleUrls: ["./contact-panel.component.scss"],
})
export class ContactPanelComponent {

  @Input() contact: Contact;

  constructor() {}
}
