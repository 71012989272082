import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import { ValidationService } from './validation.service';
import { AuthService } from './services/authentication/auth.service';
import { LoadingScreenService } from 'src/app/services/loading-screen/loading-screen.service';
import { Location } from '@angular/common';
import { DocumentService } from './services/document/document-service.service';
import { environment } from 'src/environments/environment';
import buildnumberFile from '../../buildnumber.json';
import { StyleService } from './services/style/style.service';
import { ErrorService } from "./services/error/error.service";
import { MessageService } from "./services/message/message-service.service";
import { AttachmentsService } from './services/attachments/attachments.service';
import { Observable } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {

  headerHTML: string;
  // h1 Inhalt
  public pageHeader: string = "Anmeldung im Mieterportal";

  // bei true -> Nutzer ist korrekt eingeloggt
  validLogin: boolean = false;

  // bei true -> Nutzer befindet sich in der Registrierung und hat auf der ersten Seite korrekte Daten eingegeben
  registration: boolean = false;

  // bei true -> Bürgermenü mit zusätzlicher Auswahl, bei false -> Account- Icon mit 2 Auswahlpunkten (Mein Account und Logout)
  isBurger: boolean;

  // regelt, ob der Header mit Background displayed wird oder es sich um einen Breadcrumb handelt
  public headerBackground: boolean;

  // URL
  private url: string = "";

  // zeigt einen Drucken-Button im Header an, wenn ein Dokument angezeigt wird
  public print_btn: boolean;
  public download_btn_attachment: boolean;
  public download_btn_document: boolean;
  public print_btn_attachment: boolean;

  private headerLinks: string[] = [
    "/",
    "/login",
    "/homepage",
    "/registration",
    "/registration/1",
    "/registration/2",
    "/registration/3",
    "/registration/4",
    "/registration/5",
    "/registration/6",
    "/password",
  ];

  // enthält die Daten für das Anlegen der Kacheln im Menü
  tileSampler: object[] = [
    {
      link: "/messages",
      img: '<svg class="kachel-image-svg" id="meine_nachrichten" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path class="cls-1" d="M36.81,14.17H13.19a1.57,1.57,0,0,0-1.58,1.57V34.26a1.57,1.57,0,0,0,1.58,1.57H36.81a1.57,1.57,0,0,0,1.58-1.57V15.74A1.57,1.57,0,0,0,36.81,14.17Zm-2.61,2.3L25,23.55l-9.2-7.08ZM13.91,33.53V17.92L25,26.45l11.09-8.53V33.53Z"/></svg>',
      imgInactive: '<img src="../../assets/media/meine_nachrichten_gr.svg" class="kachel-image-svg">',
      header: "Meine Nachrichten",
      active: true,
    },
    {
      link: "/agreements",
      img: '<svg class="kachel-image-svg" id="meine_vertraege-svg" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path id="Shape-2" class="cls-1" d="M30.24,21.31H19.15a.45.45,0,0,0-.36.16.66.66,0,0,0-.14.41V23a.66.66,0,0,0,.14.41.48.48,0,0,0,.36.16H30.24a.43.43,0,0,0,.36-.16.56.56,0,0,0,.14-.41V21.88a.56.56,0,0,0-.14-.41A.43.43,0,0,0,30.24,21.31Z"/><path id="Shape-3" class="cls-1" d="M18.79,16.4a.62.62,0,0,0-.14.41V18a.66.66,0,0,0,.14.41.48.48,0,0,0,.36.16H30.24a.43.43,0,0,0,.36-.16.56.56,0,0,0,.14-.41V16.81a.53.53,0,0,0-.14-.41.46.46,0,0,0-.36-.16H19.15A.45.45,0,0,0,18.79,16.4Z"/><path id="ic_create_24px" data-name="ic create 24px" class="cls-1" d="M27.54,30.58v3.17h3.17L40,24.41l-3.17-3.17Zm15-8.62a.86.86,0,0,0,0-1.19h0l-2-2a.84.84,0,0,0-1.19,0h0l-1.54,1.54L41,23.51,42.49,22Z"/><path class="cls-1" d="M30.24,28.67a.43.43,0,0,0,.36-.16.56.56,0,0,0,.14-.41v-.72l-1.3,1.29Z"/><path class="cls-1" d="M28.91,26.38H19.15a.45.45,0,0,0-.36.16.66.66,0,0,0-.14.41V28.1a.66.66,0,0,0,.14.41.48.48,0,0,0,.36.16h7.46Z"/><path class="cls-1" d="M33.53,33.75v2.34H16.47V13.91H33.53v7.84l1.93-1.92.37-.38V13.19a1.57,1.57,0,0,0-1.57-1.58H15.74a1.57,1.57,0,0,0-1.57,1.58V36.81a1.57,1.57,0,0,0,1.57,1.58H34.26a1.57,1.57,0,0,0,1.57-1.58V31.45Z"/><polygon class="cls-1" points="35.83 22.28 33.53 24.58 33.53 30.92 35.83 28.62 35.83 22.28"/><path class="cls-1" d="M25.54,31.45H19.15a.45.45,0,0,0-.36.16.66.66,0,0,0-.14.41v1.15a.62.62,0,0,0,.14.41.48.48,0,0,0,.36.16h6.39Z"/></svg>',
      imgInactive: '<img src="../../assets/media/meine_vertraege_gr.svg" class="kachel-image-svg">',
      header: "Meine Verträge",
      active: true,
    },
    {
      link: "/documents",
      img: '<svg class="kachel-image-svg" id="meine_dokumente" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path class="cls-1" d="M37.23,41.7H12.77a2.15,2.15,0,0,1-2.15-2.15V29.82a2.14,2.14,0,0,1,2.15-2.14h7.12A2.17,2.17,0,0,1,22,29.43a3.07,3.07,0,0,0,6,0,2.16,2.16,0,0,1,2.1-1.75h7.12a2.14,2.14,0,0,1,2.15,2.14v9.73A2.15,2.15,0,0,1,37.23,41.7ZM12.92,39.39H37.08V30H30.24a5.36,5.36,0,0,1-10.48,0H12.92Z"/><path id="Shape-2" class="cls-1" d="M32.54,22.6H21.45a.45.45,0,0,0-.36.16.66.66,0,0,0-.14.42v1.14a.62.62,0,0,0,.14.41.49.49,0,0,0,.36.17H32.54a.48.48,0,0,0,.36-.17.53.53,0,0,0,.14-.41V23.18a.57.57,0,0,0-.14-.42A.43.43,0,0,0,32.54,22.6Z"/><path id="Shape-3" class="cls-1" d="M21.09,17.69a.66.66,0,0,0-.14.41v1.15a.64.64,0,0,0,.14.41.48.48,0,0,0,.36.16H32.54a.43.43,0,0,0,.36-.16.54.54,0,0,0,.14-.41V18.1a.56.56,0,0,0-.14-.41.46.46,0,0,0-.36-.16H21.45A.45.45,0,0,0,21.09,17.69Z"/><path class="cls-1" d="M18.77,25.66V15.2H35.83V25.66h2.3V14.48a1.57,1.57,0,0,0-1.57-1.58H18a1.57,1.57,0,0,0-1.57,1.58V25.66Z"/><path class="cls-1" d="M28.46,8.3h-15a1.57,1.57,0,0,0-1.57,1.58V25.66h2.3V10.6H30V9.88A1.58,1.58,0,0,0,28.46,8.3Z"/></svg>',
      imgInactive: '<img src="../../assets/media/meine_dokumente_gr.svg" class="kachel-image-svg">',
      header: "Meine Dokumente",
      active: true,
    },
    {
      link: "/forms",
      img: '<svg class="kachel-image-svg" id="Formulare" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><g id="file28"><path id="Shape" class="cls-1" d="M34.72,38.39H16.29a1.51,1.51,0,0,1-1.15-.49,1.64,1.64,0,0,1-.47-1.19V13.29a1.64,1.64,0,0,1,.47-1.19,1.54,1.54,0,0,1,1.15-.49H27.13a3.83,3.83,0,0,1,1.49.35,3.94,3.94,0,0,1,1.29.84l5.28,5.43A4.14,4.14,0,0,1,36,19.56a4.21,4.21,0,0,1,.34,1.53V36.71a1.63,1.63,0,0,1-.48,1.18A1.54,1.54,0,0,1,34.72,38.39ZM16.83,13.85v22.3H34.17V22.77h-7A1.54,1.54,0,0,1,26,22.28a1.65,1.65,0,0,1-.48-1.19V13.85ZM27.67,14v6.55H34a2,2,0,0,0-.38-.71l-5.3-5.45A1.82,1.82,0,0,0,27.67,14Z"/></g><path id="Shape-2" class="cls-1" d="M31.05,26.39H24.22a.43.43,0,0,0-.36.16.56.56,0,0,0-.14.41v1.15a.56.56,0,0,0,.14.41.46.46,0,0,0,.36.16h6.83a.45.45,0,0,0,.36-.16.66.66,0,0,0,.14-.41V27a.66.66,0,0,0-.14-.41A.45.45,0,0,0,31.05,26.39Z"/><path id="Shape-2-2" data-name="Shape-2" class="cls-1" d="M31.05,31.46H24.22a.43.43,0,0,0-.36.16.56.56,0,0,0-.14.41v1.15a.53.53,0,0,0,.14.41.46.46,0,0,0,.36.16h6.83a.45.45,0,0,0,.36-.16.62.62,0,0,0,.14-.41V32a.66.66,0,0,0-.14-.41A.45.45,0,0,0,31.05,31.46Z"/><circle class="cls-1" cx="20.28" cy="27.53" r="1.15"/><circle class="cls-1" cx="20.28" cy="32.61" r="1.15"/></svg>',
      imgInactive: '<img src="../../assets/media/Formulare_gr.svg" class="kachel-image-svg">',
      header: "Formulare",
      active: true,
    },
    {
      link: "/contact",
      img: '<svg class="kachel-image-svg" id="personen" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path class="cls-1" d="M30.54,13.24a3.32,3.32,0,1,1-3.31,3.32,3.32,3.32,0,0,1,3.31-3.32m0-2.32a5.64,5.64,0,1,0,5.64,5.64,5.63,5.63,0,0,0-5.64-5.64Z"/><path class="cls-1" d="M19.46,17.54a3.32,3.32,0,1,1-3.31,3.32,3.32,3.32,0,0,1,3.31-3.32m0-2.32a5.64,5.64,0,1,0,5.64,5.64,5.64,5.64,0,0,0-5.64-5.64Z"/><path class="cls-1" d="M23.16,28.65a8.48,8.48,0,0,1-7.4,0A11.09,11.09,0,0,0,8.38,39.08H30.54A11.08,11.08,0,0,0,23.16,28.65Z"/><path class="cls-1" d="M27.88,31.9c.23.26.44.54.65.83C28.32,32.44,28.11,32.16,27.88,31.9Z"/><path class="cls-1" d="M23.16,28.65c.33.12.65.26,1,.4C23.81,28.91,23.49,28.77,23.16,28.65Z"/><path class="cls-1" d="M24.39,29.18c.24.12.47.25.7.38C24.86,29.43,24.63,29.3,24.39,29.18Z"/><path class="cls-1" d="M27.16,31.14c.21.21.42.42.61.64C27.58,31.56,27.37,31.35,27.16,31.14Z"/><path class="cls-1" d="M25.32,29.7a9.8,9.8,0,0,1,.85.58A9.8,9.8,0,0,0,25.32,29.7Z"/><path class="cls-1" d="M26.27,30.36c.24.19.47.39.7.6C26.74,30.75,26.51,30.55,26.27,30.36Z"/><path class="cls-1" d="M22,29.1l.09,0Z"/><path class="cls-1" d="M28.61,32.86c.17.25.34.51.49.78C29,33.37,28.78,33.11,28.61,32.86Z"/><path class="cls-1" d="M34.24,24.35a8.48,8.48,0,0,1-7.4,0,11.12,11.12,0,0,0-2.75,1.45l.07,0a14.13,14.13,0,0,1,8.7,9h8.76A11.08,11.08,0,0,0,34.24,24.35Z"/><path class="cls-1" d="M25.09,29.56l.23.14Z"/><path class="cls-1" d="M24.12,29.05l.27.13Z"/><path class="cls-1" d="M22.09,29.07a7.67,7.67,0,0,0,1.07-.42A7.67,7.67,0,0,1,22.09,29.07Z"/><path class="cls-1" d="M20.89,29.36A8.15,8.15,0,0,0,22,29.1,8.15,8.15,0,0,1,20.89,29.36Z"/><path class="cls-1" d="M26.17,30.28a.39.39,0,0,0,.1.08A.39.39,0,0,1,26.17,30.28Z"/><path class="cls-1" d="M29.1,33.64l.11.2Z"/><path class="cls-1" d="M28.53,32.73l.08.13Z"/><path class="cls-1" d="M27,31l.19.18Z"/><path class="cls-1" d="M27.77,31.78l.11.12Z"/></svg>',
      imgInactive: '<img src="../../assets/media/personen_gr.svg" class="kachel-image-svg">',
      header: "Ansprechpartner",
      active: true,
    },
    {
      link: "/faq",
      img: '<svg class="kachel-image-svg" version="1.1" id="fragen" x="0px" y="0px"viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#003E65;}.st1{fill:#083F64;}</style><path id="ic_help_outline_24px" class="st0" d="M23.9,32.1h2.4v-2.4h-2.4V32.1z M25,13.1c-6.5,0-11.9,5.3-11.9,11.9 S18.5,36.9,25,36.9S36.9,31.5,36.9,25S31.5,13.1,25,13.1z M25,34.5c-5.3,0-9.5-4.2-9.5-9.5s4.2-9.5,9.5-9.5s9.5,4.2,9.5,9.5 S30.3,34.5,25,34.5z M25,17.9c-2.6,0-4.8,2.2-4.8,4.8l0,0h2.4c0-1.3,1-2.4,2.4-2.4s2.4,1,2.4,2.4c0,2.4-3.5,2.1-3.5,5.9h2.4 c0-2.7,3.5-3,3.5-5.9C29.8,20,27.6,17.9,25,17.9L25,17.9z"/></svg>',
      imgInactive: '<img src="../../assets/media/fragen_gr.svg" class="kachel-image-svg">',
      header: "Häufige Fragen (FAQs)",
      active: true,
    },
  ];
  public messageCounter$: Observable<any>;

  // Variable der Seitennavigation
  @ViewChild("sidenav") sidenav: MatSidenav;
  menu_message_counter: any;

  constructor(
    private router: Router,
    private validationService: ValidationService,
    private authService: AuthService,
    private spinnerService: LoadingScreenService,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private documentService: DocumentService,
    private styleService: StyleService,
    private errorService: ErrorService,
    private messageService: MessageService,
    private attachementService: AttachmentsService
  ) {}

  ngOnInit() {
    localStorage.clear();
    console.info(environment.version + "." + buildnumberFile.buildnumber);

    // überprüft die URL
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
        this.validLogin = this.authService.isLoggedIn();
        this.print_btn = false;
        this.download_btn_attachment = false;
        this.download_btn_document = false;
        this.print_btn_attachment = false;

        // das Menü nach dem Ändern der URL schließen
        this.sidenav.close();

        // ändert das Menü -> kurze Version auf der Startseite
        this.isBurger = !(this.url.localeCompare("/homepage") === 0);

        this.messageCounter$ = this.messageService.unreadMessageCount$;
        
        // überprüfe anhand der URL, ob der Nutzer sich im Registrierungsprozess befindet
        this.registration = this.url.indexOf("registration/") !== -1;

        // wenn der Nutzer auf die Registrierung über die URL zugreifen will, ...
        if (this.url.indexOf("/registration") !== -1) {
          // ... aber bereits eingeloggt ist
          if (this.validationService.getUserToken()) {
            this.router.navigate(["/homepage"]);
          }

          if (Number.isInteger(Number.parseInt(this.url.slice(-1)))) {
            if (
              (!this.validationService.getIfIsContractNumber() &&
                this.url.localeCompare("/registration/2") === 0) || // ... und keine Vertragsnummer eingeben muss
              !this.validationService.checkRegistrationStep(
                Number.parseInt(this.url.slice(-1))
              ) // ... und einen Registrierungsschritt erreichen will, der noch nicht freigeschaltet ist
            ) {
              this.router.navigate(["/registration"]);
            }
          }
        }

        this.headerBackground = this.validationService.isElementOf(
          this.headerLinks,
          this.url
        );
        this.setPageHeader();
      }
    });
  }

  setPageHeader(){
    this.styleService.loadStyleConfig().subscribe(styles => {
      switch (this.url) {
        case "/": case "/login": this.pageHeader = this.styleService.getWelcomeText(styles); break;
        case "/password": this.pageHeader = "Passwort vergessen"; break;
        case "/registration": case "/registration/1": case "/registration/2": case "/registration/3":
        case "/registration/4": case "/registration/5": case "/registration/6": this.pageHeader =  this.styleService.getRegisterText(styles); break;
        case "/homepage": this.pageHeader = this.styleService.getHomepageText(styles); break;
        case "/messages": this.pageHeader = "Meine Nachrichten"; break;
        case "/new-message": this.pageHeader = "Neue Nachricht"; break;
        case "/replies": this.pageHeader = "Nachricht"; break;
        case "/agreements": this.pageHeader = "Meine Verträge"; break;
        case "/documents": this.pageHeader = "Meine Dokumente"; break;
        case "/document-view": this.pageHeader = ""; this.print_btn = true; this.download_btn_document = true; break;
        case "/forms": this.pageHeader = "Formulare"; break;
        case "/contact": this.pageHeader = "Ansprechpartner"; break;
        case "/account": this.pageHeader = "Mein Account"; break;
        case "/faq": this.pageHeader = "Häufige Fragen"; break;
        case "/feedback": this.pageHeader = "Feedback zur Software"; break;
        case "/attachment": this.pageHeader = "Dateianhang"; this.download_btn_attachment = true; this.print_btn_attachment = true; break;
        case "/logout": this.pageHeader = "Sie haben sich erfolgreich ausgeloggt"; break;
        default: this.pageHeader = "Page Not Found"; break;
      }

      // Sonderfall Password-Reset-URL, da dieser Parameter enthält
      if (this.url.includes("/reset?key")) {
        this.pageHeader = "Setzen Sie Ihr Passwort zurück";
        this.headerBackground = true;
      }

      this.headerHTML = this.styleService.getheaderHTML(styles);
    });
  }

  logout() {
    this.authService.logout();
  }

  menuNavigation(link: string, active: boolean) {
    if (active) {
      this.router.navigate([link]);
    }
  }


  stepBack() {
    this.location.back();
  }

  printDoc() {
    this.documentService.printDocument();
  }

  downloadOpenedDocument() {
    this.documentService.downloadOpenedDocument();
  }

  downloadOpenedAttachement() {
    this.attachementService.downloadOpenedAttachment();
  }

  printOpenedAttachment() {
    this.attachementService.printOpenedAttachment();
  }

  ngAfterViewInit(): void {
    this.spinnerService.activate();
    setTimeout(() => {
      this.spinnerService.deactivate();
    }, 1000);
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
}
