/**
 * Notification Types:
 * maps the notification types to their corresponding scss-class.
 */

export enum notificationType {
  INFO = 'snackbar-info',
  WARNING = 'snackbar-warning',
  ERROR = 'snackbar-error',
  DEBUG = 'snackbar-debug'
}
