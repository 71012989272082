<section class="component-container centered header-distance">
    <h2>Geben Sie bitte Ihre Anmeldedaten ein.</h2>

       <form #loginForm="ngForm" class="form-display" (keydown)="submitLoginByKeypress($event, loginIsValid(loginForm))">
           <mat-form-field class="first-field">
               <mat-label>Anmeldename</mat-label>
               <input matInput type="email" #email="ngModel" name="email" appAutofocus
                   pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" [(ngModel)]="emailAdress"
                   required>
               <mat-error>Bitte geben Sie eine gültige E-Mail-Adresse ein.</mat-error>
           </mat-form-field>
           <mat-form-field class="second-field">
               <mat-label>Passwort</mat-label>
               <input matInput type="password" #password="ngModel" name="password"
                   [(ngModel)]="hiddenPassword" required>
               <mat-hint align="end"><span class="link" routerLink="/password">Passwort vergessen?</span></mat-hint>
           </mat-form-field>
       </form>

        <button type="submit" color="primary" class="button-confirm primary"
            [class.inactive]="!loginIsValid(loginForm)" mat-button
            (click)="login(loginIsValid(loginForm))">Anmelden</button>

    <h2>Falls Sie noch nicht registriert sind, <br>klicken Sie bitte <span class="link"
        routerLink="/registration">hier</span>.</h2>
</section>
