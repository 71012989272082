import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingScreenService } from '../services/loading-screen/loading-screen.service';
import { StyleService } from '../services/style/style.service';


const SPINNER_MESSAGE = 'Loading...';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})

export class LoadingScreenComponent implements OnInit {
  @Input() show: boolean;
  LOADING_TEXT: string;
  SPINNER_COLOR= "#00A5D7";

  constructor(private spinner: NgxSpinnerService,
              private spinnerService: LoadingScreenService,
              private styleService: StyleService) {}

  ngOnInit() {
      this.LOADING_TEXT = SPINNER_MESSAGE;

      this.spinnerService.getData().subscribe(data => {
          if (data) {
              this.spinner.show();
          } else {
              this.spinner.hide();
          }
      });
  }

  ngAfterViewInit(){
    this.styleService.loadStyleConfig().subscribe(styles => {
      this.SPINNER_COLOR = this.styleService.getPrimaryColor(styles);
    })

  }
}
