import { Component, OnInit, Input } from "@angular/core";
import { MessageService } from "src/app/services/message/message-service.service";
import { ConversationMessage } from "src/app/model/ConversationMessage";

@Component({
  selector: "app-message-history",
  templateUrl: "./message-history.component.html",
  styleUrls: ["./message-history.component.scss"],
})
export class MessageHistoryComponent implements OnInit {
  @Input() conversation: ConversationMessage;
  constructor(private messageService: MessageService) {}

  ngOnInit() {}
}
