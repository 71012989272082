export class Contact {
  role: string;
  fullName: string;
  mail: string;
  company: {
    cpyName: string;
    streetAndNo: string;
    postcode: string;
    cityName: string;
  }
}
