import { Injectable } from "@angular/core";
import { Contract } from "src/app/model/Contract";
import { DocumentSearchFilter } from "src/app/model/DocumentSearchFilter";
import { Message } from "src/app/model/Message";
import { ValidationService } from "src/app/validation.service";
import { MessageType } from "../../model/MessageTypes";
import { BackendService } from "../backend/backend.service";
import { ConversationMessage } from "src/app/model/ConversationMessage";
import { Observable, Subject } from "rxjs";
import {map, delay } from "rxjs/operators";
import { ShortDeDatePipe } from "src/app/pipes/date.pipe";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  public messageSearchFilter: DocumentSearchFilter;
  private message: Message = new Message();
  private contracts: Array<Contract> = new Array<Contract>();
  private messageTypes: Array<MessageType> = new Array<MessageType>();
  public selectedAgreementForMessage: string = "";
  public selectedMessageTypeForMessage: string = "";
  public selectedMessageForReplies: Message;
  public preselectedAgreementForMessage: string;
  public messageFormModel: any;
  private classRowSingle = "form-output-field single";
  private classRowDouble = "form-output-field";

  public unreadMessageCount$: Observable<any>;


  private datePipe = new ShortDeDatePipe("de");
  constructor(private backendService: BackendService, private validationService: ValidationService) {
    this.initDocumentSearchFilters();
  }

  getMessageSearchFilter(): DocumentSearchFilter {
    return this.messageSearchFilter;
  }

  getAllMessages() {
    return this.backendService.getMessages(this.validationService.getUserToken().supreamGuid);
  }

  getAllMessagesTypes() {
    return this.messageTypes;
  }

  getComments(){
    return this.backendService.getMessageComments(this.validationService.getUserToken().supreamGuid, this.message.id);
  }

  getSumOfNotifications() {
    const MESSAGES = 0
    // Zuweisung benötigt, weil erst HomeComponent den Request triggern soll. Das Observable unreadMessageCount$ wird aber vorher schon in der App Component zugewiesen. 
    this.unreadMessageCount$ = this.backendService.getNotificationCounter(this.validationService.getUserToken().supreamGuid)
    .pipe(
      map(notification => {
        return JSON.parse(notification)[MESSAGES].count;
      })
    );
    return this.unreadMessageCount$;
  }

  initDocumentSearchFilters() {
    this.messageSearchFilter = new DocumentSearchFilter();
  }

  setAgreementInput(value: string, lockAgreementFilter: boolean) {
    this.messageSearchFilter.agreementInput = value;
    this.messageSearchFilter.agreementFilterLocked = lockAgreementFilter;
  }

  buildHTMLMessageDetail(jsonMessageDetails){
    let htmlForm = '<section id="form-output">';

    if(jsonMessageDetails['labelRows']){
      const json = Array.from(jsonMessageDetails['labelRows']);

      json.forEach(labelGroups => {
        htmlForm += '<div class="form-output-row">';
        labelGroups['labels'].forEach(label => {
          let classRow = label.gridSizeX == 2 ? this.classRowSingle : this.classRowDouble
          htmlForm += '<section class="' + classRow + '">';

          if (label.dataType === 'date') {
            label.value = this.datePipe.transform(label.value)
          }
            htmlForm +='<span class="form-output-label">' +label.name +'</span>' +'<p class="form-output-text">' +label.value.replace(/\n/g, '<br>') +'</p>';
            htmlForm += '</section>'
          });
        htmlForm += '</div>';
        });
    }
    htmlForm += '</section>';
    return htmlForm;
  }

  buildHTMLMessage(mergedMessageForm, fieldContent ) {
    let htmlForm = '<section id="form-output">';

    for (let key in mergedMessageForm) {
      htmlForm += '<div class="form-output-row">';

      if (mergedMessageForm[key].fieldGroup) {
        for (let formGroup in mergedMessageForm[key].fieldGroup) {
          let formField = mergedMessageForm[key].fieldGroup[formGroup];
          let classRow = formField.className.includes('single-form-row') ? this.classRowSingle : this.classRowDouble

          htmlForm += '<section class="' + classRow + '">';
          htmlForm += '<span class="form-output-label">' + formField.templateOptions.label + '</span>';

          if (formField.type === 'radio' || formField.type === 'select') {
            for (let fieldOption in formField.templateOptions.options) {
              if (formField.templateOptions.options[fieldOption].value == fieldContent[formField.key]) {
                htmlForm += '<p class="form-output-text">' + formField.templateOptions.options[fieldOption].label + '</p>';
              }
            }
          } else {
            if (formField?.templateOptions?.type  === 'date') {
              htmlForm += '<p class="form-output-text">' + this.datePipe.transform(fieldContent[formField.key]) +'</p>';
            }
            // Sonderfall bei Betreff soll immer fett sein
            else if(formField?.type === 'input' && formField?.templateOptions?.label === 'Betreff') {
              htmlForm += '<p class="form-output-text subject-text">' + fieldContent[formField.key] + '</p>';
            }
            else if(formField?.type === 'datepicker'){
              htmlForm += '<p class="form-output-text">' + fieldContent?.date.toLocaleDateString()+'</p>';
            }
            else if(formField?.type  === 'textarea'){
              htmlForm += '<p class="form-output-text">' + fieldContent[formField.key].replace(/\n/g, '<br>') + '</p>';
            }
            else{
              htmlForm += '<p class="form-output-text">' + fieldContent[formField.key] + '</p>';
            }
          }
          htmlForm += '</section>';
        }
      }
      htmlForm += '</div>';
    }

    htmlForm += '</section>';
    return htmlForm;
  }

  prepareMessage(htmlMessage, jsonMessage) {

    for (var key in jsonMessage) {
      jsonMessage[key] = jsonMessage[key].toString()
    }

    return {
      "contractId": this.selectedAgreementForMessage,
      "messageTemplateId": this.selectedMessageTypeForMessage.toString(),
      "inputElementsData": JSON.stringify(jsonMessage)
    }

  }

  submitForm(htmlMessage, jsonMessage): Observable<any> {
    let messageID = new Subject<string>();
    this.backendService.postMessage(this.validationService.getUserToken().supreamGuid, this.prepareMessage(htmlMessage, jsonMessage))
      .subscribe((response) => {
        return messageID.next((response.body as any).messageId);
      });

      return messageID.asObservable();
  }
}
