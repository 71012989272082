<mat-sidenav-container [hasBackdrop]="false">
    <!-- Menü -->
    <mat-sidenav #sidenav mode="over" position="end" (keydown.escape)="sidenav.close()"  class="menu">
        <div class="navbar navbar-menu">
            <div class="navbar-menu-text">
                <h2>Menü</h2>
            </div>
            <div class="return-button-wrapper">
                <button mat-icon-button (click)="sidenav.close()" class="return-button"></button>
            </div>
        </div>

        <div class="navbar-content">
            <section *ngIf="isBurger">
                <div class="pointer kachel" [class.kachel-inactive]="!tile.active" *ngFor="let tile of tileSampler;" (click)="menuNavigation(tile.link, tile.active)">
                    <div class="kachel-icon">
                        <div class="svg-container" [innerHTML]="tile.active ? (tile.img | safe) : tile.imgInactive"></div>
                        <span *ngIf="tile.link == '/messages'" class="kachel-count" [matBadge]="messageCounter$ | async" [matBadgeHidden]="(messageCounter$ | async)  == 0  || !tile.active"></span>
                    </div>
                    <div class="kachel-text"><h2>{{tile.header}}</h2></div>
                </div>
                <div class="kachel-spacer"></div>
            </section>
            <section>
                <div class="pointer kachel" routerLink="/account">
                    <div class="kachel-icon svg-container">
                        <svg class="kachel-image-svg" version="1.1" id="person" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#083F64;}</style><path class="st0" d="M25,15.4c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0,0,0,0,0,0 C21.7,16.9,23.2,15.4,25,15.4 M25,13.1c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6l0,0C30.6,15.6,28.1,13.1,25,13.1 C25,13.1,25,13.1,25,13.1z"/><path class="st0" d="M28.7,26.5c-2.3,1.1-5.1,1.1-7.4,0c-4.4,1.6-7.4,5.7-7.4,10.4h22.2C36.1,32.2,33.1,28.1,28.7,26.5z"/></svg>
                    </div>
                    <div class="kachel-text"><h2>Mein Account</h2></div>
                </div>
                <div class="pointer kachel" (click)="logout()">
                    <div class="kachel-icon svg-container">
                        <svg class="kachel-image-svg" id="logout_2" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path id="Shape-2" class="cls-1" d="M26.15,25.58V14.5a.43.43,0,0,0-.17-.37.62.62,0,0,0-.41-.14H24.43a.62.62,0,0,0-.41.14.46.46,0,0,0-.17.37V25.58a.42.42,0,0,0,.17.36.53.53,0,0,0,.41.14h1.14a.53.53,0,0,0,.41-.14A.42.42,0,0,0,26.15,25.58Z"/><path class="cls-1" d="M32.9,18.77l-.5.5a.8.8,0,0,0-.09,1,8.71,8.71,0,1,1-14.62,0,.8.8,0,0,0-.09-1l-.51-.51a.81.81,0,0,0-1.24.13,11,11,0,1,0,18.29,0A.81.81,0,0,0,32.9,18.77Z"/></svg>
                    </div>
                    <div class="kachel-text"><h2>Logout</h2></div>
                </div>
            </section>
        </div>
    </mat-sidenav>
    <!-- Seiteninhalt -->
    <mat-sidenav-content>

        <!-- Navbar -->
        <mat-toolbar class="navbar">
            <mat-toolbar-row>
                <img src="../assets/media/Logo.png" routerLink="/homepage" class="logo" id="logo">
                <button mat-icon-button (click)="sidenav.open()" class="button-wrapper" *ngIf="validLogin">
                    <svg *ngIf="isBurger" class="burger-button" id="Burger" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path id="Shape" class="cls-1" d="M35.74,31.4H14.26a1,1,0,0,0-.69.27.85.85,0,0,0-.28.64v1.82a.85.85,0,0,0,.28.64,1,1,0,0,0,.69.27H35.74a1,1,0,0,0,.69-.27.85.85,0,0,0,.28-.64V32.31a.85.85,0,0,0-.28-.64A1,1,0,0,0,35.74,31.4Z"/><path id="Shape-2" class="cls-1" d="M36.43,15.23a.94.94,0,0,0-.69-.27H14.26a1,1,0,0,0-.69.27.81.81,0,0,0-.28.64v1.82a.85.85,0,0,0,.28.64.94.94,0,0,0,.69.27H35.74a1,1,0,0,0,.69-.27.85.85,0,0,0,.28-.64V15.87A.85.85,0,0,0,36.43,15.23Z"/><path id="Shape-3" class="cls-1" d="M35.74,23.18H14.26a1,1,0,0,0-.69.27.81.81,0,0,0-.28.64v1.82a.85.85,0,0,0,.28.64.94.94,0,0,0,.69.27H35.74a1,1,0,0,0,.69-.27.85.85,0,0,0,.28-.64V24.09a.85.85,0,0,0-.28-.64A1,1,0,0,0,35.74,23.18Z"/></svg>
                    <svg *ngIf="!isBurger" class="account-button" version="1.1" id="person" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#083F64;}</style><path class="st0" d="M25,15.4c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0,0,0,0,0,0 C21.7,16.9,23.2,15.4,25,15.4 M25,13.1c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6l0,0C30.6,15.6,28.1,13.1,25,13.1 C25,13.1,25,13.1,25,13.1z"/><path class="st0" d="M28.7,26.5c-2.3,1.1-5.1,1.1-7.4,0c-4.4,1.6-7.4,5.7-7.4,10.4h22.2C36.1,32.2,33.1,28.1,28.7,26.5z"/></svg>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <!-- Header-Bereich -->
        <header [class.header-background]="headerBackground" [class.header-breadcrumb]="!headerBackground">
            <section class="custom-header-elements" [innerHTML]="headerHTML"></section>
            <div *ngIf="!headerBackground && validLogin" class = "tooltip">  
                <span class = "tooltiptext">Eine Seite zurück</span>          
                <svg class="pointer breadcrumb-arrow" id="Pfeil_links" (click)="stepBack()" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#083f64;}</style></defs><path class="cls-1" d="M23.27,25l6.81-6.81a1.41,1.41,0,0,0,0-2l-1.31-1.32a1.42,1.42,0,0,0-2,0L20,21.69,17.65,24a1.42,1.42,0,0,0,0,2L20,28.31l6.81,6.81a1.42,1.42,0,0,0,2,0l1.31-1.32a1.41,1.41,0,0,0,0-2Z"/></svg>
            </div>
            <h1 [innerHTML]="pageHeader"></h1>
            <button mat-icon-button class="download-button" *ngIf="download_btn_document" (click)="downloadOpenedDocument()">
                <span class="button-text">Download</span>
                <img class="print-icon" src="assets/media/Download_w.svg">
            </button>
            <button mat-icon-button class="download-button" *ngIf="download_btn_attachment" (click)="downloadOpenedAttachement()">
                <span class="button-text">Download</span>
                <img class="print-icon" src="assets/media/Download_w.svg">
            </button>
            <button mat-icon-button class="print-button" *ngIf="print_btn" (click)="printDoc()">
                <span class="button-text">Drucken</span>
                <img class="print-icon" src="assets/media/Drucken_w.svg">
            </button>
            <button mat-icon-button class="print-button" *ngIf="print_btn_attachment" (click)="printOpenedAttachment()">
                <span class="button-text">Drucken</span>
                <img class="print-icon" src="assets/media/Drucken_w.svg">
            </button>
        </header>
        <nav class="register-nav" *ngIf="registration">
            <div *ngFor="let i of [1,2,3,4,5,6]" class="nav-circle" [class.invisible]="i == 2 && !validationService.getIfIsContractNumber()" [class.filled]="url.slice(-1) == i"></div>
        </nav>

        <!-- Komponenteninhalt -->
        <router-outlet></router-outlet>
        <app-loading-screen></app-loading-screen>


    </mat-sidenav-content>
</mat-sidenav-container>
