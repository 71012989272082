import { Component, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { User } from 'src/app/model/User';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  passwordType: string = "password";
  public emailField: string = "";
  public passwordField: string = "xxxxxxxxxxxx";
  @ViewChild(ChangePasswordComponent) changePasswordView: ChangePasswordComponent;

  public passwordForm: FormGroup;

  constructor(private backendService: BackendService, public validationService: ValidationService, private fb: FormBuilder, private authService: AuthService) {
    this.emailField = this.validationService.getEmail();
  }
  public user: User;

  ngOnInit() {
    this.backendService.getUser(this.validationService.getUserToken().supreamGuid).subscribe(data => this.user = JSON.parse(data));
  }

  changePassword() {
    this.changePasswordView.showPopup = true;
  }
}
