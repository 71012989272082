import { Component, Input, OnInit } from '@angular/core';
import { StyleService } from 'src/app/services/style/style.service';


@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  // Dateipfad
  public url;
  // Datei ist eine PDf
  isPDF: boolean = true;
  // PDF-Seiten
  page: number = 1;
  totalPages: number;
  image;
  numberContainer: number[] = [];

  NAVBUTTONPATH;

  @Input() blob!: Blob;

  constructor(private styleService: StyleService
  ) { }

  ngOnInit() {
    this.setDocumentPropertys(this.blob);
    this.getNavButtonPath();
  }

  setDocumentPropertys(newBlob: Blob) {
    if (newBlob.type == "application/pdf") {
      this.isPDF = true;
      this.url = URL.createObjectURL(newBlob);
    } else {
      this.isPDF = false;
      const reader = new FileReader();
      reader.onload = (e) => this.image = e.target.result;
      reader.readAsDataURL(newBlob);
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    for (let i = 0; i < this.totalPages; i++) {
      this.numberContainer.push(i);
    }
  }

  // Diese Funktion löscht den localstorage und lädt die Seite neu,
  // wenn die File nicht über die URL aufgerufen werden kann.
  onError(error: any) {
    if (error.name = "MissingPDFException") {
      //this.authService.clearLocalstorageSafe();
      window.location.reload();
    }
  }

  // Navigation durch die PDF eine Seite vorwärts oder rückwärts
  nextPage() {
    if (this.page < this.totalPages) {
      this.page++;
    }
  }
  prevPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  // Navigation durch die PDF über direktes Anwählen von Seiten
  changePage(selectedPage: number) {
    this.page = selectedPage;
  }

  getNavButtonPath() {
    this.styleService.loadStyleConfig().subscribe(styles => {
      this.NAVBUTTONPATH = this.styleService.getNavButtonPath(styles);
    });
  }

}
