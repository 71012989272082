import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { AuthService } from '../services/authentication/auth.service';
import { PasswordService } from '../services/password/password.service';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from '../validation.service';
import { ErrorService } from '../services/error/error.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public passwordForm: FormGroup;
  changedPw: boolean = false;
  showPopup: boolean;
  failureMessage: string;
  resetPasswordKey:string;
  isLoggedIn: boolean;

  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private validationService: ValidationService) { }

  ngOnInit(): void {
    this.setResetTokenFromURL();
    this.passwordForm = this.passwordService.createPasswordForm();

    if (this.resetPasswordKey && !this.authService.isLoggedIn()) {
      this.showPopup = true;
      this.isLoggedIn = false;
      this.passwordForm.addControl("mail", this.fb.control(null, [Validators.required, Validators.email]))
    }
    else {
      this.isLoggedIn = true;
      this.passwordForm.addControl("oldPassword", this.fb.control(null, Validators.required))
    }
  }


  setupPasswordForm(): void {
    Object.keys(this.passwordForm.controls).forEach(key => {
      if (key) {
        this.passwordForm.controls[key].setValue("");
        this.passwordForm.controls[key].markAsUntouched();
      }
    });

    this.failureMessage = "";
  }

  setResetTokenFromURL(){
    this.route.queryParams.subscribe(params => {
      this.resetPasswordKey = params["key"];
    });
  }

  confirmChangedPw() {
    this.changedPw = false;
    this.showPopup = false;
  }

  cancelPasswordChange() {
    this.showPopup = false;
    this.setupPasswordForm();
  }

  confirm() {
    if (this.isLoggedIn && this.passwordFieldsValid() && this.getOldPasswordFormValue() === this.validationService.getPassword()) {
      this.changePassword(this.validationService.getEmail());      
    } else if (!this.isLoggedIn && this.resetPasswordKey) {
      this.passwordService.validateResetToken(this.getMailAdressFormValue(), this.resetPasswordKey).subscribe(tokenIsValid => {
        if (tokenIsValid && this.passwordFieldsValid()) {
          this.resetPassword(this.getMailAdressFormValue());
        } else {
          this.passwordService.getErrorMessagePasswordOrTokenInvalid();
        }
      })
    }
  }

  resetPassword(email: string) {
    this.passwordService.resetUserPassword(email, this.getPasswordFormValue()).subscribe();
  }

  changePassword(email: string) {
    this.passwordService.changeUserPassword(email, this.getPasswordFormValue(), this.getOldPasswordFormValue()).subscribe(
      result => this.showPopup = !result
    );
  }


  passwordFieldsValid(): boolean {
    if (this.isLoggedIn) {
      return this.getOldPasswordFormValue() !== this.getPasswordFormValue() 
      && this.passwordForm.controls['password'].valid 
      && this.passwordForm.controls['confirmPassword'].valid;
    }
    else {
      return this.passwordForm.controls['mail'].valid
      && this.passwordForm.controls['password'].valid 
      && this.passwordForm.controls['confirmPassword'].valid;
    }
  }

  getPasswordFormValue(): any {
    return this.passwordForm.controls['password']?.value ? this.passwordForm.controls['password'].value : "";
  }

  getOldPasswordFormValue(): any {
    return this.passwordForm.controls['oldPassword']?.value ? this.passwordForm.controls['oldPassword'].value : "";
  }

  getMailAdressFormValue(): any {
    return this.passwordForm.controls['mail']?.value ? this.passwordForm.controls['mail'].value : "";
  }
}
