import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { FileSizePipe } from './file-size.pipe';
import { ShortDeDatePipe } from './date.pipe';

@NgModule({
  declarations: [
    SafePipe,
    FileSizePipe,
    ShortDeDatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    FileSizePipe,
    ShortDeDatePipe
  ]
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
    };
  }
}
