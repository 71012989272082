import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/services/error/error.service';
import { httpStatusCode } from 'src/app/shared/httpStatusCode';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  emailAdress: string;

  constructor(private authService: AuthService, private router: Router, private errorService: ErrorService) { }

  ngOnInit(): void {
  }

  cancel() {
    this.router.navigate(["/"]);
  }

  continue(valid: boolean) {

    if (valid) {
      this.authService.sendResetPasswordMail(this.emailAdress).subscribe(response => {
        if (response.status == httpStatusCode.ACCEPTED) {
          this.errorService.getSendResetMailsSuccessfull();
          this.router.navigate(["/login"]);
        }
      }, err => {
        this.errorService.getMailNotRegistered();
      });
    }
  }

  emailIsValid(formValues: any): boolean {
    return formValues.value.email != undefined && formValues.value.email != '' && formValues.valid;
  }
}
