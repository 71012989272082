import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MessageService } from "../services/message/message-service.service";
import { Router } from "@angular/router";
import { MessageType } from "../model/MessageTypes";
import { Contract } from "../model/Contract";
import { BackendService } from "../services/backend/backend.service";
import { ValidationService } from "../validation.service";
import { MatGridTileHeaderCssMatStyler } from "@angular/material";
import { ConversationService } from "../services/message/conversation-service.service";
import { AttachmentsService } from "../services/attachments/attachments.service";
import { Message } from "../model/Message";
import { MessageAttachment } from "../model/MessageAttachment";

@Component({
  selector: "app-new-message",
  templateUrl: "./new-message.component.html",
  styleUrls: ["./new-message.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewMessageComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private router: Router,
    private backendService: BackendService,
    private validationService: ValidationService,
    private conversationService: ConversationService,
    private attachmentService: AttachmentsService
  ) {}

  contracts = new Array<Contract>();
  distinctContracts = new Array<Contract>();
  messageTypes = new Array<MessageType>();
  allMessageTypes = new Array<MessageType>();
  messageForm: any;
  selectedMessage: Message;
  attachmentsWithThumbnail: Array<MessageAttachment>;

  originalMessage: any;

  disableRadioButtonGroupAgreements = false;
  disableRadioButtonGroupMessageTypes = false;
  showCreateMessageComponent = false;
  allowCreateMessageComponent = false;
  displayAgreements = true;
  showSendButton = false;
  showMessageType = false;
  toggleHeadlines = false;

  messageId: string;

  ngOnInit() {
    this.backendService
      .getUserContracts(this.validationService.getUserToken().supreamGuid)
      .subscribe((data) => {
        this.contracts = JSON.parse(data);
      });
    this.allMessageTypes = this.messageService.getAllMessagesTypes();

  }

  ngOnChanges() {
    this.messageService.selectedMessageTypeForMessage = undefined;
  }

  ngOnDestroy() {
    this.messageService.selectedAgreementForMessage = undefined;
    this.messageService.selectedMessageTypeForMessage = undefined;
  }

  goBackToInbox() {
    this.router.navigateByUrl("/messages");
  }

  checkForPreselectedContract(contracts) {
    contracts.forEach(contract => {
      if (contract.number == this.messageService.preselectedAgreementForMessage) {
        this.contracts = new Array<Contract>();
        this.contracts.push(contract);
        this.disableRadioButtonGroupAgreements = true;
      } else {
        this.messageService.preselectedAgreementForMessage = undefined;
      }
    });
  }

  filterMessageTypes(agreement) {
    if (agreement) {
      this.messageService.selectedAgreementForMessage = agreement;
      this.backendService.getMessageTypes(
        this.validationService.getUserToken().supreamGuid, agreement)
        .subscribe((data) => {
          this.allMessageTypes = JSON.parse(data);
        });
    }
    //TODO ist das notwendig?: this.showMessageType = !this.showMessageType;
    this.showMessageType = true;
  }

  allowCreateMessage(messageType) {
    if (messageType) {
      this.allowCreateMessageComponent = true;
    }
  }

  checkIfMessageTypeEmpty() {
    return this.allMessageTypes.length != 0;
  }

  displayToCreateMessage() {
    if (this.allowCreateMessageComponent) {
      this.getMessageForm();
      this.disableRadioButtonGroupAgreements = true;
      this.disableRadioButtonGroupMessageTypes = true;
      this.toggleHeadlines = true;
      this.showCreateMessageComponent = !this.showCreateMessageComponent;
      this.displayAgreements = false;
      this.allowCreateMessageComponent = false;
      this.showSendButton = true;
    }
  }

  getMessageForm() {
    this.backendService.getMessageForms(
      this.validationService.getUserToken().supreamGuid, this.messageService.selectedAgreementForMessage, this.messageService.selectedMessageTypeForMessage)
      .subscribe((data) => {
        this.messageForm = JSON.parse(data);
    });

  }

  onSubmitForm(event) {
    this.originalMessage = event;
  }

  onSubmitFormMessageId(event){
    this.messageId = event;
  }

  buildThumbnails(){
    this.conversationService.getOriginalMessage(this.messageId).subscribe(message => {
      this.selectedMessage = JSON.parse(message);

      this.attachmentService.getThumbnailForAttachment(this.selectedMessage).subscribe(result => {
        if(result){
          this.attachmentsWithThumbnail = result;
        }
      });
    });
  }

}
