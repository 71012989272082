import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValidationService } from 'src/app/validation.service';
import data from '../../../assets/data/config.json';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  private x_api_key: string;

  constructor(public validation: ValidationService) {
    this.x_api_key = data[0].x_api_key;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${this.validation.token}`)
        .set('x-api-key', this.x_api_key)
    });

    return next.handle(request);
  }
}
