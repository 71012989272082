import { Injectable } from '@angular/core';
import { notificationType } from 'src/app/shared/notificationType';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  getNoDocumentsFound() {
    this.notification.show(notificationType.INFO,"Es liegen keine Dokumente vor.");
  }

  getChangePasswordSuccessfull() {
    this.notification.show(notificationType.INFO,"Das Passwort wurde erfolgreich geändert.");
  }

  getSendResetMailsSuccessfull() {
    this.notification.show(notificationType.INFO,"Der Rücksetzungslink wurde erfolgreich verschickt.");
  }

  getInternalErrorMessage() {
    this.notification.show(notificationType.ERROR,"Es ist ein interner Fehler aufgetreten");
  }

  getUserAlreadyExistsMessage() {
    this.notification.show(notificationType.ERROR,"Der angegebene Benutzer existiert bereits!");
  }

  getResetPasswordError() {
    this.notification.show(notificationType.ERROR,"Fehler beim setzen des neuen Passworts");
  }

  getResetWrongPasswordError() {
    this.notification.show(notificationType.ERROR,"Das eingegebene Passwort ist falsch");
  }

  getResetPasswordInvalid(){
    this.notification.show(notificationType.ERROR,"Der Rücksetzungslink ist leider nicht gültig oder es liegt ein Fehler im Passwort vor.");
  }

  getDefaultErrorMessage(){
    this.notification.show(notificationType.ERROR, "Es ist ein unerwarteter Fehler aufgetreten");
  }

  getContractNumberInvalidMessage(){
    this.notification.show(notificationType.ERROR, "Die eingegebene Vertragsnummer ist nicht korrekt.");
  }

  getInvalidUserDataError() {
    this.notification.show(notificationType.ERROR,"Bitte prüfen Sie die korrekte Eingabe von Ihrer E-Mail und/oder Passwort.");
  }

  getUserDeactivatedError() {
    this.notification.show(notificationType.ERROR, "Der Benutzer ist deaktiviert");
  }

  getUserKennungError() {
    this.notification.show(notificationType.ERROR, "Bitte prüfen Sie die korrekte Eingabe von Initialisierungskennung und/oder Initialisierungscode.");
  }

  getTermsUncheckedError() {
    this.notification.show(notificationType.ERROR, "Bitte akzeptieren Sie die Nutzungsbedingungen, bevor Sie fortfahren.");
  }

  getRegistrationStepsNotFinished(){
    this.notification.show(notificationType.INFO, "Führen Sie bitte erst die vorangegangenen Registrierungsschritte durch.");
  }

  getInvalidEMailAdress(){
    this.notification.show(notificationType.ERROR, "Bitte verwenden Sie eine gültige und noch nicht verwendete E-Mail.");
  }

  getInvalidBestaetigungscode(){
    this.notification.show(notificationType.ERROR, "Bitte prüfen Sie die korrekte Eingabe Ihres Bestätigungscodes.");
  }

  getMissingBestaetigungscode(){
    this.notification.show(notificationType.ERROR, "Bitte geben Sie den Bestätigungscode an, bevor Sie fortfahren.");
  }

  getMailNotRegistered(){
    this.notification.show(notificationType.ERROR, "Die Angegebene E-Mail-Adresse ist nicht registriert.");
  }

  getRequiredFormFieldsNotFilled(){
    this.notification.show(notificationType.ERROR, "Es sind nicht alle Pflichtfelder befüllt.");
  }

  getCommentNotFilled(){
    this.notification.show(notificationType.ERROR, "Es wurde keine Antwort erfasst.");
  }

  getCommentCouldNotSend(){
    this.notification.show(notificationType.ERROR, "Die Antwort konnte nicht übermittelt werden.");
  }

  getSendCommentSuccessfull() {
    this.notification.show(notificationType.INFO,"Die Antwort wurde erfolgreich übermittelt.");
  }

  getNoAttachmentSelected(){
    this.notification.show(notificationType.ERROR, "Es wurde keine Datei ausgewählt.");
  }

  getAttachmentCouldNotSend(){
    this.notification.show(notificationType.ERROR, "Die Datei konnte nicht übermittelt werden.");
  }

  getSendAttachmentSuccessfull() {
    this.notification.show(notificationType.INFO,"Die Datei wurde erfolgreich übermittelt.");
  }
  
  attachmentInvalidType(validFilesTypes : string){
    this.notification.show(notificationType.ERROR,"Der Dateityp ist ungültig. Gültige Dateitypen sind " + validFilesTypes);
  }

  attachementSizeTooBig(maxFileSize: number){
    this.notification.show(notificationType.ERROR,"Die Datei ist zu groß. Die maximale Größe beträgt " + maxFileSize + " MB");
  }

  userSessionExpired() {
    this.notification.show(notificationType.INFO,"Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.", true);
  }
  constructor(private notification: NotificationService) { }
}
