<div class="msg-wrapper" *ngIf="hideIfNotChecked()">
  <div class="msg-elements" [ngClass]="getStyleActive() ? 'selected' : 'unselected'" (click)="selectAgreement(contract.contractId)">
    <div class="msg-radio-button-group-wrapper">
      <div class="msg-radio-button-group">
        <mat-radio-button name="selectAgreement" class="msg-radio-button" value="1"
          (change)="selectAgreement(contract.contractId)" [checked]="checked"></mat-radio-button>
      </div>
    </div>
    <div class="message-infos">
      <div class="address">
        <p>{{ contract.unitStreetAndNo }}</p>
        <div class="city-postcode">
          <p class="postal-code-location">{{ contract.unitPostcode + " " + contract.unitCity }}</p>
        </div>
        <p>{{ contract.number }}</p>
      </div>
      <div class="details">
        <p>{{ contract.unitInfo1 }}</p>
        <p>{{ contract.unitInfo2 }}</p>
        <p>{{ contract.unitInfo3 }}</p>
      </div>
    </div>
  </div>
</div>
