import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import data from '../../../assets/data/config.json';
import { UserToken } from 'src/app/model/userToken';
import { ValidationService } from 'src/app/validation.service';
import { Observable, of, Subject } from 'rxjs';
import { BackendService } from '../backend/backend.service';
import { UserSettings } from 'src/app/model/UserSettings';
import { User } from 'src/app/model/User';
import { AgbAccepted } from 'src/app/model/AgbAccepted';
import { ErrorService } from '../error/error.service';
import { httpStatusCode } from '../../shared/httpStatusCode';
import { Router } from '@angular/router';

const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

@Injectable()
export class AuthService {

  private apiURL: string;
  private userCredentials: any;

  constructor(private http: HttpClient, private validationService: ValidationService, private backendService: BackendService, private errorService: ErrorService, private router: Router) {
    this.apiURL = data[0].backendURL;
  }

  doLogin(): Observable<string> {
    var message = new Subject<string>();

    this.authenticate().subscribe(response => {
      if (response.status == httpStatusCode.OK) {
        var user;
        // Wenn User im AAD vorhanden muss geprüft werden, ob dieser auch in Supream aktiv / hinterlegt ist:
        this.backendService.getUser(this.validationService.getUserToken().supreamGuid).subscribe(userdata => {
          user = JSON.parse(userdata);
          if(user.userIdentifier){
            message.next();
          }
        }, error => {
            this.errorService.getUserDeactivatedError();
        });
      } else {
        this.errorService.getInvalidUserDataError();
      }
    });

    return message.asObservable();
  }

  authenticate(): Observable<HttpResponse<any>> {
    this.userCredentials = { Username: this.validationService.getEmail(), Password: this.validationService.getPassword() };
    var httpResponse = new Subject<HttpResponse<any>>();

    this.http.post(this.apiURL + '/AcquireUserToken', this.userCredentials, { responseType: 'text', headers, observe: 'response' }).subscribe(response => {
      if (response.status == httpStatusCode.OK) {
        this.handleToken(response.body);
        httpResponse.next(response);
      }
    }, error => httpResponse.next(error));

    return httpResponse.asObservable();
  }


  registerUser(): Observable<string> {
    var message = new Subject<string>();

    this.createUser().subscribe(response => {
      if (response.status == httpStatusCode.OK || response.status == httpStatusCode.CREATED) {
        this.backendService.postUserSettings(this.validationService.getGuid(), this.buildUserSettings(response.body)).subscribe(result => {
          if (result) {
            this.authenticate().subscribe(response => {
              this.validationService.setLoginValidation(this.isLoggedIn());
              this.router.navigate(["/homepage"]);
            });
          } else {
            this.errorService.getInternalErrorMessage();
          }
        },
          error => {
            this.errorService.getInternalErrorMessage();
          });
      } else {
        this.errorService.getDefaultErrorMessage();
      }
    },
      error => {
        if (error.status == httpStatusCode.BAD_REQUEST) {
          this.errorService.getUserAlreadyExistsMessage();
        }
        else {
          this.errorService.getInternalErrorMessage();
        }
      });

    return message.asObservable();
  }

  createUser(): Observable<HttpResponse<any>> {
    this.userCredentials = { Username: this.validationService.getEmail(), Password: this.validationService.getPassword(), SupreamGUID: this.validationService.getGuid() };

    return this.http.post(this.apiURL + '/RegisterNewUser', this.userCredentials, { responseType: 'text', headers, observe: 'response' });
  }

  resetUserPassword(email: string, newPassword: string): Observable<HttpResponse<any>> {
    this.userCredentials = { Username: email, Password: newPassword };
    return this.http.post(this.apiURL + '/ResetUserPassword', this.userCredentials, { responseType: 'text', headers, observe: 'response' });
  }

  changeUserPassword(email: string, newPassword: string, oldPassword: string): Observable<HttpResponse<any>> {
    this.userCredentials = { Username: email, Password: newPassword, OldPassword: oldPassword };
    return this.http.post(this.apiURL + '/ChangeUserPassword', this.userCredentials, { responseType: 'text', headers, observe: 'response' });
  }

  sendResetPasswordMail(receiverMail: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiURL + '/SendResetPasswordMail' + `?receiverMail=` + receiverMail, { responseType: 'text', headers, observe: 'response' });
  }

  approveUserVerificationCode(receiverMail: string, verificationCode: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiURL + '/ApproveUserVerificationCode' + `?receiverMail=` + receiverMail + `&verificationCode=` + verificationCode, { responseType: 'text', headers, observe: 'response' });
  }

  approvePasswordResetToken(receiverMail: string, approvePasswordResetToken: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiURL + '/user/validateResetToken' + `?receiverMail=` + receiverMail + `&token=` + approvePasswordResetToken, { responseType: 'text', headers, observe: 'response' });
  }

  isLoggedIn() {
    return this.validationService.getUserToken() != null;
  }

  handleUserVerificationMail(receiverMail: string): Observable<string> {
    var message = new Subject<string>();

    this.sendUserVerificationMail(receiverMail).subscribe(response => {
      //202 = Sendgrid-Code for "Accepted"
      if (response.status == httpStatusCode.ACCEPTED) {
        this.validationService.setEmail(receiverMail);
        this.validationService.changeProgress(true);
      }
      else {
        message.next(response.statusText);
      }
    },
      err => {
        if (err.status == httpStatusCode.BAD_REQUEST) {
          this.errorService.getDefaultErrorMessage();
        }
        else {
          this.errorService.getInvalidEMailAdress();
        }
      });
    return message.asObservable();
  }

  sendUserVerificationMail(receiverMail: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiURL + '/SendUserVerificationMail' + `?receiverMail=` + receiverMail, { responseType: 'text', headers, observe: 'response' });
  }

  private handleToken(token: string) {

    //var decodedToken = jwt_decode(token["access_token"]);
    this.validationService.setUserToken(token);
  }
  logout(userSessionExpired : boolean = false) {
    if(userSessionExpired){
      this.errorService.userSessionExpired();
    }
    this.validationService.deleteUserToken();
    this.router.navigate(["/login"]);
  }

  private buildUserSettings(azureObjectId : string): UserSettings {
    let userSettings: UserSettings ={
      agbAcception: this.buildAgbAccepted(),
      mailingAccepted: this.validationService.getNoPaper(),
      onboardingSuccessfull: true,
      adUserPrincipalName: this.validationService.getEmail(),
      adObjectId: azureObjectId
    }

    return userSettings;
  }

  private getUser(): User {
    let user = new User();
    this.backendService.getUser(this.validationService.getGuid()).subscribe(data => user = JSON.parse(data));

    return user;
  }

  private buildAgbAccepted(): AgbAccepted {
    let agb: AgbAccepted = {
      accepted: this.validationService.getTerms(),
      version: this.validationService.getAgreedTermsVersion(),
      timestamp: new Date().toLocaleTimeString()
    }
    return agb;
  }
}
