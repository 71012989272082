<section class="component-wrapper">
  <div class="left-side">
    <header class="component-header">
      <h3>Meine persönlichen Daten</h3>
      <div class="header-bar"></div>
    </header>

    <form class="placeholder-container very-small-margin-bottom" *ngIf="user">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" #name="ngModel" name="surname" [(ngModel)]="user.surname" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput type="text" #name="ngModel" name="name" [(ngModel)]="user.name" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Straße und Hausnummer</mat-label>
        <input matInput type="text" #street="ngModel" name="street" [(ngModel)]="user.streetAndNo" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Postleitzahl</mat-label>
        <input matInput type="text" #postal="ngModel" name="postal" [(ngModel)]="user.postcode" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Wohnort</mat-label>
        <input matInput type="text" #city="ngModel" name="city" [(ngModel)]="user.city" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput type="text" #phone="ngModel" name="phone" [(ngModel)]="user.phone" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Handynummer</mat-label>
        <input matInput type="text" #mobile="ngModel" name="mobile" [(ngModel)]="user.mobile" disabled>
      </mat-form-field>
    </form>
  </div>

  <div class="right-side">
    <header class="component-header">
      <h3>Meine Anmeldedaten</h3>
      <div class="header-bar"></div>
    </header>

    <form class="placeholder-container very-small-margin-bottom">
      <mat-form-field>
        <mat-label>Anmeldename</mat-label>
        <input matInput type="text" #email="ngModel" name="email" [(ngModel)]="emailField" disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input matInput [type]="passwordType" #password="ngModel" name="password" [(ngModel)]="passwordField" disabled>
        <svg matSuffix class="svg-icon tinier" (click)="changePassword()" version="1.1" id="Vertraege" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><style type="text/css">.st0{fill:#003E65;}.st1{fill:#FFFFFF;}.st2{fill:#00A5D7;}</style><g id="Gruppe_219" transform="translate(-59.998 -437.809)"><g id="Gruppe_217" transform="translate(60 437.809)"><path id="Differenzmenge_14" class="st0" d="M31.05,44.95H8.92c-2.42,0.02-4.4-1.91-4.43-4.33V10.33C4.53,7.91,6.51,5.98,8.92,6 h22.13c2.42-0.02,4.4,1.91,4.43,4.33v12.01l-4.43,4.71V10.33H8.92v30.29h22.13v-1.81l4.43-4.22v6.02 C35.45,43.03,33.47,44.97,31.05,44.95z M21.8,35.15H10.27v-3.01H21.8V35.15L21.8,35.15z M28.73,29.13H10.27v-3.01h18.46V29.13 L28.73,29.13z M28.73,23.11H10.27v-3.01h18.46V23.11L28.73,23.11z M28.73,17.09H10.27v-3.01h18.46V17.09L28.73,17.09z"/></g><path id="ic_create_24px" class="st0" d="M84.73,472.79v4.33h4.33l12.76-12.76l-4.33-4.33L84.73,472.79z M105.16,461.01 c0.45-0.45,0.45-1.18,0-1.62c0,0,0,0,0,0l-2.7-2.7c-0.45-0.45-1.18-0.45-1.62,0c0,0,0,0,0,0l-2.11,2.11l4.33,4.33L105.16,461.01 L105.16,461.01z"/></g></svg>
        <div class="underline"></div>
      </mat-form-field>
    </form>
  </div>

  <app-change-password></app-change-password>

</section>
