import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  maxFileSize: number;
  private fileExtensions: string[] =[];
  
  constructor(private backend: BackendService, private errorService: ErrorService) {
    this.backend.getMimeTypeConfig().subscribe(result => {
      const uploadRestriction = JSON.parse(result);
      this.fileExtensions = uploadRestriction.fileExtensions;
      this.maxFileSize = uploadRestriction.maxFileSize;
      this.buildFileExtensionsString();
    });
  }

  validateFile(file): boolean {
    return file && this.isFileSizeValid(file) && this.isFileTypeValid(file);
  }

  isFileSizeValid(file): boolean { 
    if (this.maxFileSize < file.size) {
      this.errorService.attachementSizeTooBig(this.convertByteToMb(this.maxFileSize));
      return false;
    }
    return true;
  }

  isFileTypeValid(file): boolean {
    let filetype: string = file.type;
    let isAllowedFileType = this.fileExtensions.some(ex => {
      return filetype.includes(ex);
    });

    if (!isAllowedFileType) {
      this.errorService.attachmentInvalidType(this.buildFileExtensionsString());
      return false;
    }
    return true;
  }

  buildFileExtensionsString(): string{
    let transformedFileExtentions = "";
    this.fileExtensions.forEach(element => {
      transformedFileExtentions += ("." + element + ", ")
    });
    // Slice um letztes Space+Komma zu entfernen 
    transformedFileExtentions = transformedFileExtentions.slice(0, -2);
    return transformedFileExtentions;
  }

  private convertByteToMb(bytes : number): number {
    const megabyteConversion = 1024 * 1024;
    const megabytes = bytes / megabyteConversion;
    return megabytes;
  }
}
