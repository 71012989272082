export class DocumentSearchFilter {
  searchInput: string;

  dateInputStart: Date;
  dateInputEnd: Date;

  // Statusfilter
  criteriaInput: string;

  // Vertragsfilter
  agreementInput: string;

  agreementFilterLocked: boolean = false;
}
