<div class="msg-type-wrapper" *ngIf="hideIfNotChecked()">
  <div class="msg-type-elements" [ngClass]="getStyleActive() ? ' selected' : 'unselected'" (click)="selectMessageType(messageType)">
    <div class="msg-type-radio-button-group-wrapper">
      <div class="msg-type-radio-button-group">
        <mat-radio-button name="selectMessage" class="msg-radio-button" value="messageType.title"
          (change)="selectMessageType(messageType)" [checked]="checked">
        </mat-radio-button>
      </div>
    </div>
    <div class="message-type-infos">
      <div class="message-type-content">
        <p class="message-type-title">{{ messageType.title }}</p>
        <p class="message-type-description">{{ messageType.info }}</p>
      </div>
    </div>
  </div>
</div>
