import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { Contract } from 'src/app/model/Contract';

// Datenstruktur für angezeigte Daten zu den Dokumenten
type contactType = {
  description: string;
  level: string; // TODO: Format abklären
  side: string;
  street: string;
  location: string;
  agreementNumber: string;
  begin: string;
  end: string;
  tenantAmountRange: string;
  tenantAmountActual: string;
  area: string;
  totalRent: string;
  contactPerson: [
    {
      name: string;
      phone
    }

  ]
};

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(private router: Router, private backendService: BackendService, public validationService: ValidationService) {}
  public contracts: Contract[];

  ngOnInit() {
    this.backendService.getUserContracts(this.validationService.getUserToken().supreamGuid).subscribe(data => this.contracts = JSON.parse(data));
  }

  message(vermieter: string) {
    // aktuell noch nicht umgesetzt
    //this.router.navigate(["/messages"]); // TODO: muss noch für den jeweiligen vermieter konkretisiert werden
  }
}
