<section class="component-wrapper doc-component-wrapper">
    <form class="filter-container" *ngIf="documentSearchFilter">
        <div class="filter-wrapper">
            <mat-form-field class="free-text-form">
                <input matInput #findField placeholder="Finden" name="findField" [value]="this.getSearchInputForFilter()" (input)="find($event?.target?.value)">
                <mat-icon matSuffix class="findFieldSearchIcon">search</mat-icon>
                <div *ngIf="findField.value !== ''" class="delete-input" (click)="deleteFind();"><mat-icon>backspace</mat-icon></div>
            </mat-form-field>

            <mat-form-field class="date-form">
                <input matInput #dateField name="dateField" placeholder="Datum" [satDatepicker]="picker" (dateInput)="date($event)" [value]="dateRange">
                <sat-datepicker #picker
                    [rangeMode]="true"
                    [closeAfterSelection]="true"
                    [selectFirstDateOnClose]="true"
                ></sat-datepicker>
                <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                <div *ngIf="dateField.value !== ''" class="delete-input" (click)="deleteDate();"><mat-icon>backspace</mat-icon></div>
            </mat-form-field>
        </div>
        <div class="filter-wrapper">
            <mat-form-field class="category-field">
                <mat-label>Kategorie</mat-label>
                <mat-select disableRipple panelClass="panel" name="criteriaField"  [(value)]="this.documentSearchFilter.criteriaInput" (selectionChange)="criteria($event.value)">
                    <mat-option [value]="null" selected>(leer)</mat-option>
                    <mat-option *ngFor="let category of filterCategories" [value]="category">{{ category }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="agreement-field">
                <mat-label>Vertrag</mat-label>
                <mat-select disableRipple panelClass="panel" name="agreementField" [(value)]="this.documentSearchFilter.agreementInput" (ngModelChange)="agreement($event)" [disabled]="filterDisabled">
                    <mat-option [value]="null" selected>(leer)</mat-option>
                    <mat-option *ngFor="let contract of filterContractNumbers" [value]="contract">{{ contract }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

    <div *ngFor="let doc of documents" class="doc-wrapper">
        <app-document-panel [document]="doc"></app-document-panel>
    </div>
    <div *ngIf="!documents || documents?.length == 0" class="no-match">
        <p>Ihre Suche ergab leider keinen Treffer.</p>
    </div>
</section>
