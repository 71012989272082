<section class="message-form-wrapper" *ngIf="!toggleForm">
<div class="form-wrapper">
<form [formGroup]="form">
  <formly-form [model]="fieldContent" [fields]="mergedMessageForm" [options]="options" [form]="form">
  </formly-form>
</form>
</div>
<div class="annotation">
  <p>Sie können im nächsten Schritt noch Anlagen zu Ihrer Nachricht hinzufügen.</p>
</div>
<div class="button-wrapper">
  <button mat-button class="button abort secondary" (click)="goBackToInbox()">
    <p class="button-text">Abbrechen</p>
  </button>
  <button mat-button class="button send primary" [ngClass]="isValid() ? false : 'inactive'" (click)="submit()">
    <mat-icon matSufix class="sendButtonicon">send</mat-icon>
    <p>Senden</p>
  </button>
</div>
</section>
