import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ErrorService } from "./error.service";
import { httpStatusCode } from "src/app/shared/httpStatusCode";
import { AuthService } from "../authentication/auth.service";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private errorService: ErrorService,
    private zone: NgZone,
    private authService: AuthService
  ) {}

  handleError(error: any): void {
    this.zone.run(() => {
      if (error.status && error.status === httpStatusCode.UNAUTHORIZED ) {
        //True weil UserSessionExpired
        this.authService.logout(true);
      } else {
        this.errorService.getInternalErrorMessage();
      }

      // TODO: Logging auf Azure für Frontend einführen
    });
  }
}
