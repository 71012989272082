import { Message } from 'src/app/model/Message';

export enum MessageStatus {
  active = "active",
  closed = "closed",
  unread = "unread"
}

export enum MessageStatusDisplayValues {
  active = "aktiv",
  closed = "geschlossen",
  unread = "ungelesen"
}

export function getMessageState(message: Message) {
  switch (true) {
    case (message.commentsUnread > 0):
      return MessageStatusDisplayValues.unread;
    default:
      return MessageStatusDisplayValues[message.state];
  }
}
