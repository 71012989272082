import { Injectable } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ShortDeDatePipe } from "src/app/pipes/date.pipe";

const formlyRow = (fieldConfig: FormlyFieldConfig[]) => {
  return {
    fieldGroupClassName: "display-flex",
    fieldGroup: fieldConfig,
  };
};

@Injectable({
  providedIn: "root",
})
export class FormFieldsService {
  private datePipe = new ShortDeDatePipe("de");
  currentDate = this.datePipe.transform(new Date().toString());
  constructor() { }

  initFixedInputFields(recipient) {
    return [
      recipient ? formlyRow([this.fixedDateField(), this.fixedRecipientField(recipient)]) : formlyRow([this.fixedDateField()]),
      formlyRow([this.fixedSubjectField()]),
    ];
  }

  fixedDateField() {
    return  {
      className: "flex-1",
      key: "date",
      type: "input",
      defaultValue: this.currentDate,
      templateOptions: {
        label: "Datum",
        placeholder: "Datum",
        disabled: true,
        required: true,
      },
    }
  }

  fixedRecipientField(recipient) {
    return {
      className: "flex-1",
      key: "recipient",
      type: "input",
      defaultValue: recipient,
      templateOptions: {
        label: "Empfänger",
        placeholder: "",
        disabled: true,
      },
    }
  }

  fixedSubjectField(){
    return {
      className: "flex-1",
      key: "subject",
      type: "input",
      templateOptions: {
        label: "Betreff",
        placeholder: "Nachricht verfassen",
        required: true,
      },
    }
  }
}
