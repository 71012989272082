export class User {
    userIdentifier: string;
    name: string;
    surname: string;
    fullname: string;
    streetAndNo: string;
    postcode: string;
    city: string;
    phone: string;
    mobile: string;
}
