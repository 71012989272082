import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import data from "../../../assets/data/config.json";
import { UserSettings } from "src/app/model/UserSettings.js";
import { Comment } from "src/app/model/ConversationMessage";
import { ValidationService } from "src/app/validation.service";
import { httpStatusCode } from "src/app/shared/httpStatusCode";
import { ErrorService } from "../error/error.service";


const headers = new HttpHeaders({
  "Content-Type": "text/plain",
});

@Injectable()
export class BackendService {
  private configContainer: string[];

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private validationService: ValidationService,
    private errorService: ErrorService
  ) {
    this.apiURL = data[0].backendURL;
  }

  getTermsOfUse(): Observable<any> {
    //const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this.http.get(this.apiURL + "/content/termsofuse", {
      responseType: "text",
      headers,
    });
  }

  getUser(userId: string): Observable<any> {
    return this.http.get(this.apiURL + "/content/" + userId, {
      responseType: "text",
      headers: headers,
      observe: "body",
    });
  }

  private getTextblock(textId: number): Observable<any> {
    return this.http.get(this.apiURL + "/content/texts/?id=" + textId, {
      responseType: "text",
      headers: headers,
      observe: "body",
    });
  }

  textblock(id: number): Observable<string> {
    var textblockhtml = new Subject<string>();

    this.getTextblock(id).subscribe((textresponse) => {
      textblockhtml.next(JSON.parse(textresponse)[0].content);
    });

    return textblockhtml.asObservable();
  }

  getUserContracts(userId: string): Observable<any> {
    return this.http.get(this.apiURL + "/content/" + userId + "/contracts", {
      responseType: "text",
      headers: headers,
      observe: "body",
    });
  }

  getContractByNumber(userId: string, contractNumber: string) : Observable<any> {
    return this.http.get(this.apiURL + "/user/" + userId + `/contractNumber?contractNumber=${contractNumber}`,
      { responseType: "text", headers: headers, observe: "body"}
    );
  }

  getMessageTypes(userId: string, contractId: string): Observable<any> {
    return this.http.get( this.apiURL +  "/user/" + userId + `/messageTemplate?contractId=${contractId}`,
      { responseType: "text", headers: headers, observe: "body" }
    );
  }

  getMessageForms(userId: string, contractId: string, templateId: string): Observable<any> {
    return this.http.get( this.apiURL + "/message/" + userId + `/messageformdefinition/${templateId}?contractId=${contractId}`,
      { responseType: "text", headers: headers, observe: "body" }
    );
  }

  postMessage(userId: string, message: any){
    return this.http.post(this.apiURL + "/message/" + userId + "/messages",  message, { observe: "response" });
  }

  getMessages(userId: string): Observable<any>{
    return this.http.get( this.apiURL + "/message/" + userId + `/messages`,
      { responseType: "text", headers: headers, observe: "body" }
    );
  }

  getMessageComments(userId: string, messageId: string): Observable<any> {
    return this.http.get( this.apiURL + "/message/" + userId + "/message/" + messageId + "/comments",
      { responseType: "text", headers: headers, observe: "body" }
    );
  };

  postMessageComment(userId: string, messageId: string, comment: Comment) : Observable<any>{
    return this.http.post(this.apiURL + "/message/" + userId + "/message/" + messageId + "/comments",
      comment, { observe: "response"});
  }

  getMessageById(userId: string, messageId: string): Observable<any>{
    return this.http.get( this.apiURL + "/message/" + userId + `/message/${messageId}`,
      { responseType: "text", headers: headers, observe: "body" }
    );
  }

  postMessageAttachment(userId: string, messageId: string, fileAttachment: FormData): Observable<any>{
    return this.http.post(this.apiURL + "/message/" + userId + "/message/" + messageId + "/attachment",
    fileAttachment, { responseType: "text" });
  }

  getMessageAttachment(userId: string, messageId: string, attachemtnId:string): Observable<any>{
    return this.http.get( this.apiURL + "/message/" + userId + "/message/" + messageId + `/attachment/${attachemtnId}`,
      { responseType: "blob", headers: headers, observe: "body" }
    );
  }

  getMessageAttachmentThumbnail(userId: string, messageId: string, attachemtnId:string): Observable<any>{
    return this.http.get( this.apiURL + "/message/" + userId + "/message/" + messageId + `/attachment/${attachemtnId}/thumbnail`,
    { responseType: "blob", headers, observe: "body" }
    );
  }

  getUserForms(userId: string): Observable<any> {
    return this.http.get(this.apiURL + "/content/" + userId + "/forms", {
      responseType: "text",
      headers: headers,
      observe: "body",
    });
  }

  getUserFormBinary(userId: string, formuId: string): Observable<any> {
    return this.http.get(
      this.apiURL + "/content/" + userId + "/formBinary/" + formuId,
      { responseType: "blob", headers, observe: "body" }
    );
  }

  getUserDocuments(userId: string): Observable<any> {
    return this.http.get(this.apiURL + "/content/" + userId + "/documents", {
      responseType: "text",
      headers: headers,
      observe: "body",
    });
  }

  getUserDocumentBinary(userId: string, documentId: string): Observable<any> {
    return this.http.get(
      this.apiURL + "/content/" + userId + "/documentBinary/" + documentId,
      { responseType: "blob", headers, observe: "body" }
    );
  }

  startRegistration(
    identification: string,
    registrationCode: string
  ): Observable<string> {
    var message = new Subject<string>();

    this.signUp(identification, registrationCode).subscribe(
      (data) => {
        this.getContractNumberMandatory(data).subscribe((response) =>
          this.validationService.setIfIsContractNumber(
            JSON.parse(response.body).value == 1
          )
        );

        this.validationService.setGuid(data);
        localStorage.setItem("reg0", "true");
        this.validationService.changeProgress(true);
      },
      (err) => {
        localStorage.setItem("reg0", "false");
        if (err.status == 400) {
          this.errorService.getUserKennungError();
        } else {
          this.errorService.getDefaultErrorMessage();
        }
      }
    );
    return message.asObservable();
  }

  digitalCorrespondenceOnly(): Observable<string> {
    var digitalCorrespondenceHTML = new Subject<string>();

    this.getDigitalCorrespondenceOnly(
      this.validationService.getGuid()
    ).subscribe((response) => {
      var digitalCorrespondence = JSON.parse(response.body);
      if (digitalCorrespondence.value == 1) {
        this.validationService.setShowNoPaperHTML(true);
        digitalCorrespondenceHTML.next(digitalCorrespondence.text);
      } else {
        digitalCorrespondenceHTML.next("");
      }
    });

    return digitalCorrespondenceHTML.asObservable();
  }

  signUp(
    userVerificationIdentifier: string,
    verificationCode: string
  ): Observable<string> {
    return this.http.get(
      this.apiURL +
        "/user/signup" +
        `?userVerificationIdentifier=${userVerificationIdentifier}&verificationCode=${verificationCode}`,
      { responseType: "text", headers }
    );
  }

  getContractNumberMandatory(userId: string): Observable<HttpResponse<any>> {
    return this.http.get(
      this.apiURL + "/user/" + userId + "/contractNumberMandatory",
      { responseType: "text", headers, observe: "response" }
    );
  }

  private getDigitalCorrespondenceOnly(
    userId: string
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      this.apiURL + "/user/" + userId + "/digitalCorrespondenceOnly",
      { responseType: "text", headers, observe: "response" }
    );
  }

  validateContractNumber(
    userId: string,
    contractNumber: string
  ): Observable<string> {
    var errorMessage = new Subject<string>();

    this.getContractNumberValid(userId, contractNumber).subscribe(
      (response) => {
        if (response.status == httpStatusCode.OK) {
          errorMessage.next();
        }
      },
      (error) => {
        this.errorService.getContractNumberInvalidMessage();
      }
    );

    return errorMessage.asObservable();
  }

  private getContractNumberValid(
    userId: string,
    contractNumber: string
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      this.apiURL +
        "/user/" +
        userId +
        `/contractNumber?contractNumber=${contractNumber}`,
      { responseType: "text", headers, observe: "response" }
    );
  }

  postUserSettings(userId: string, userSettings: UserSettings) {
    return this.http.post(
      this.apiURL + "/user/" + userId + "/userSettings",
      userSettings,
      { observe: "response" }
    );
  }

  getUserGuid(userId: string): Observable<string> {
    return this.http.get(this.apiURL + "/azure/" + userId, {
      responseType: "text",
    });
  }

  patchUserGuid(userId: string, value: string) {
    return this.http.patch(this.apiURL + "/azure/" + userId, null);
  }

  getUserFAQs(userId: string): Observable<HttpResponse<any>> {
    return this.http.get(this.apiURL + "/content/" + userId + "/faqs", {
      responseType: "text",
      headers,
      observe: "response",
    });
  }
  
  getMimeTypeConfig(): Observable<any>{
    return this.http.get(this.apiURL + "/configuration/accepted-mime-types", {
      responseType: "text",
    });
  }

  getNotificationCounter(userId: string): Observable<any> {
    return this.http.get(this.apiURL + "/user/" + userId + "/notifications/counters", {
      responseType: "text",
    });
  }
}
