<section class="component-wrapper replies-component-wrapper">
  <div class="replies-title-element-wrapper">
    <div class="replies-title-elements">
      <div class="date">
        <p class="messageDate">{{ selectedMessage.date | shortDeDate }}</p>
      </div>
      <div class="message-infos">
        <div class="address">
          <p>{{ selectedMessage.info1 }}</p>
          <div class="city-postcode">
            <div class="city">
              <p>{{ selectedMessage.info2 }}</p>
            </div>
          </div>
          <p>{{ selectedMessage.info3 }}</p>
        </div>
        <div class="details">
          <p>{{ selectedMessage.info4 }}</p>
          <p>{{ selectedMessage.info5 }}</p>
          <p>{{ selectedMessage.info6 }}</p>
        </div>
        <div class="notifications">
          <p>{{ selectedMessage.info7 }}</p>
          <p>{{ selectedMessage.info8 }}</p>
          <p>{{ selectedMessage.info9 }}</p>
        </div>
        <div class="address-responsive">
          <p>{{ selectedMessage.info1 }}</p>
          <div class="city-postcode">
            <div class="city">
              <p>{{ selectedMessage.info2 }}</p>
            </div>
          </div>
          <p>{{ selectedMessage.info3 }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="header-content">
    <div class="header-replies-wrapper">
      <mat-icon>question_answer</mat-icon>
      <p class="header">Nachrichtenverlauf</p>
    </div>
  </div>
  <div class="form-field-wrapper">
    <p class="message-header">Neue Antwort schreiben</p>
    <div class="message-input-background">
      <div class="message-input-wrapper">
        <div class="message-input">
          <mat-form-field appearance="fill" floatLabel="always">
            <textarea matInput cdkTextareaAutosize="true" [(ngModel)]="replyMessage"></textarea>
          </mat-form-field>
        </div>
        <div class="button-wrapper">
          <button mat-flat-button class="send-message primary" (click)="sendReply()">
            <mat-icon matSuffix>send</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="message-history-wrapper" *ngFor="let conv of conversations">
    <app-message-history [conversation]="conv"></app-message-history>
  </div>
  <div class="header-content">
    <div class="header-replies-wrapper">
      <mat-icon>message</mat-icon>
      <p class="header">Nachricht</p>
    </div>
  </div>
  <div class="original-message">
    <div class="rendered-message" [innerHTML]="originalMessage"></div>
  </div>
  <div class="header-content">
    <div class="header-replies-wrapper">
      <mat-icon>attach_file</mat-icon>
      <p class="header">Alle Anlagen</p>
    </div>
  </div>
  <div class="file-upload-wrapper">
    <div *ngFor="let attachment of this.attachmentsWithThumbnail">
      <app-thumbnail-box [messageAttachment]="attachment"></app-thumbnail-box>
    </div>
    <app-file-upload [messageId]="selectedMessage.id" (uploadDialogClosed)="onUploadDialogClosed()"></app-file-upload>
  </div>
</section>
