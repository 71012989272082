import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomepageComponent } from "./pages/homepage/homepage.component";
import { MessagesComponent } from "./pages/messages/messages.component";
import { AgreementsComponent } from "./pages/agreements/agreements.component";
import { DocumentsComponent } from "./pages/documents/documents.component";
import { FormsComponent } from "./pages/forms/forms.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { AccountComponent } from "./pages/account/account.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { FeedbackComponent } from "./pages/feedback/feedback.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { LoginComponent } from "./pages/authentication/login/login.component";
import { AuthGuardService } from "./services/authentication/auth-gard.service";
import { DocumentViewComponent } from "./pages/document-view/document-view.component";
import { ResetPasswordComponent } from "./pages/authentication/reset-password/reset-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { NewMessageComponent } from "./new-message/new-message.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { RepliesComponent } from "./message-panel/replies/replies.component";
import { AttachmentComponent } from "./pages/attachment/attachment.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", redirectTo: "" },
  { path: "logout", redirectTo: "" },
  { path: "password", component: ResetPasswordComponent },
  {
    path: "registration",
    loadChildren: () =>
      import("./pages/authentication/registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: "homepage",
    component: HomepageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "messages",
    component: MessagesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "new-message",
    component: NewMessageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "replies",
    component: RepliesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "agreements",
    component: AgreementsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "documents",
    component: DocumentsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "document-view",
    component: DocumentViewComponent,
    canActivate: [AuthGuardService],
  },
  { path: "forms", component: FormsComponent, canActivate: [AuthGuardService] },
  {
    path: "contact",
    component: ContactComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "account",
    component: AccountComponent,
    canActivate: [AuthGuardService],
  },
  { path: "faq", component: FaqComponent, canActivate: [AuthGuardService] },
  {
    path: "feedback",
    component: FeedbackComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "attachment",
    component: AttachmentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reset",
    children: [{ path: "**", component: ChangePasswordComponent }],
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  LoginComponent,
  HomepageComponent,
  MessagesComponent,
  NewMessageComponent,
  AgreementsComponent,
  DocumentsComponent,
  DocumentViewComponent,
  FormsComponent,
  ContactComponent,
  AccountComponent,
  FaqComponent,
  FeedbackComponent,
  PageNotFoundComponent,
];
