
import { Contract } from "./Contract";
import { MessageStatus } from "src/app/shared/messageStatus";
import { ConversationMessage } from "./ConversationMessage";
import { MessageAttachment } from "./MessageAttachment";

export class Message {
  id: string;
  date: Date;
  state: MessageStatus;

  info1: string
  info2: string
  info3: string
  info4: string
  info5: string
  info6: string
  info7: string
  info8: string
  info9: string //message Subject

  attachments: MessageAttachment[];
  commentsUnread: number;
  contract: Contract;

  conversation: Array<ConversationMessage>;
}
