<section class="component-container header-distance">
    <h2>Passwort zurücksetzen</h2>

    <p class="small-margin-bottom">Um Ihr Passwort zurückzusetzen geben Sie bitte Ihre E-Mail-Adresse an.
        Sie erhalten dadurch in Kürze eine E-Mail mit einem entsprechenden Rücksetzungslink.
    </p>

    <form #userForm="ngForm" class="registration-forms email">
        <h2 class="small-margin-bottom">Ihre E-Mail-Adresse</h2>

        <mat-form-field>
            <mat-label>Meine E-Mail-Adresse</mat-label>
            <input matInput type="email" #email="ngModel" name="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" [(ngModel)]="emailAdress" appAutofocus>
            <mat-error>Bitte geben Sie eine gültige E-Mail-Adresse an.</mat-error>
        </mat-form-field>

        <p class="no-margin-bottom">Die Zustellung des Rücksetzungslinks kann manchmal etwas dauern. Bitte haben Sie Verständnis dafür.</p>

        <div class="spacer-wrapper">
            <button color="secondary" class="button-small button-cancel secondary" mat-button (click)="cancel()">Abbrechen</button>
            <button color="primary" class="button-small button-continue primary" [class.inactive]="!emailIsValid(userForm)" mat-button (click)="continue(emailIsValid(userForm))">Weiter</button>
        </div>
    </form>
</section>
