<mat-expansion-panel hideToggle>
    <mat-expansion-panel-header class="category">
        <mat-panel-title (click)="toView()">
            <div class="arrow-box">
                <div class="arrow">
                    <svg id="pfeil_unten_mit_kreis" data-name="Ebene 1" viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#083f64;}</style></defs><circle class="cls-1" cx="25" cy="25" r="15.88"/><path class="cls-2" d="M25,27.26l-7.44-7.44a1.53,1.53,0,0,0-2.17,0L14,21.25a1.55,1.55,0,0,0,0,2.18l7.44,7.44,2.52,2.52a1.55,1.55,0,0,0,2.18,0l2.52-2.52,7.44-7.44a1.55,1.55,0,0,0,0-2.18l-1.44-1.43a1.53,1.53,0,0,0-2.17,0Z"/></svg>
                </div>
            </div>
            <div class="title-content">
                <div class="title-element-wrapper">
                    <div class="title-elements">
                        <div class="first-text-block">
                            <p>{{ document.date }}</p>
                        </div>
                        <div>
                            <p>{{ document.category }}</p>
                            <p class="desktop">{{ document.info1 }}</p>
                            <p class="desktop">{{ document.info2 }}</p>
                        </div>
                        <div class="desktop">
                            <p>Vertr.-Nr. {{ document.contractNumber }}</p>
                            <p>{{ document.info3 }}</p>
                            <p>{{ document.info4 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-panel-title>
        <mat-panel-description>
            <div class="description-elements">
                <div class="doc-size">
                    <div class="svg-container-doc-type" [innerHTML]="documentService.checkDocumentEnding(document.mimeType) | safe"></div>
                    <p>{{ document.dataSize }} kB</p>
                </div>
                <div class="doc-buttons">
                    <a class="doc-view" (click)="toView()">
                        <button mat-button >
                            <img class="img-icon eye" src="assets/media/Auge_w.svg">
                        </button>
                    </a>
                    <a download (click)="download()">
                        <button class="doc-download" mat-button>
                            <img class="img-icon download" src="assets/media/Download_w.svg">
                        </button>
                    </a>
                </div>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="content-container">
            <div class="doc-info">
                <p>Vertr.-Nr. {{ document.contractNumber }}</p>
                <p>{{ document.info1 }}, {{ document.info2 }}</p>
                <p>{{ document.info3 }}, {{ document.info4 }}</p>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>
<div class="doc-buttons small-margin-bottom">
    <a class="doc-view" (click)="toView()">
        <button mat-button>
            <img class="img-icon eye" src="assets/media/Auge_w.svg">
        </button>
    </a>
    <a download (click)="download()">
        <button class="doc-download" mat-button>
            <img class="img-icon download" src="assets/media/Download_w.svg">
        </button>
    </a>
</div>
