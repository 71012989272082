<section class="password-window-wrapper" [class.password-reset-wrapper]="!this.isLoggedIn" *ngIf="showPopup">
  <div class="password-window">
    <form [formGroup]="passwordForm" #userForm="ngForm">
      <mat-form-field *ngIf="!this.isLoggedIn">
        <mat-label>E-Mail-Adresse</mat-label>
        <input matInput type="email" formControlName="mail" appAutofocus>
      </mat-form-field>

      <mat-form-field *ngIf="this.isLoggedIn">
        <mat-label>Altes Passwort</mat-label>
        <input matInput type="password" formControlName="oldPassword">
      </mat-form-field>

      <mat-form-field [class.negative-margin-bottom]="this.isLoggedIn">
        <mat-label>Neues Passwort</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error>
          <span *ngIf="passwordForm.controls['password'].hasError('hasNumber')">Das Passwort muss eine Zahl enthalten. </span>
          <span *ngIf="passwordForm.controls['password'].hasError('hasCapitalCase')">Das Passwort muss einen Großbuchstaben enthalten. </span>
          <span *ngIf="passwordForm.controls['password'].hasError('hasSmallCase')">Das Passwort muss einen Kleinbuchstaben enthalten. </span>
          <span *ngIf="passwordForm.controls['password'].hasError('hasSpecialCharacters')">Das Passwort muss ein Sonderzeichen enthalten. </span>
          <span *ngIf="passwordForm.controls['password'].hasError('minlength')">Das Passwort muss mindestens 8 Zeichen lang sein. </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Neues Passwort wiederholen</mat-label>
        <input matInput type="password" formControlName="confirmPassword">
        <mat-error *ngIf="passwordForm.controls['confirmPassword'].hasError('NoPassswordMatch')">Die Passwörter sind nicht identisch.</mat-error>
      </mat-form-field>

      <div class="spacer-wrapper">
        <button color="primary" class="button-small button-cancel secondary" mat-button
          (click)="cancelPasswordChange()" *ngIf="this.isLoggedIn">Abbrechen</button>
        <button color="primary" class="button-small button-confirm primary"
          [class.inactive]="!passwordFieldsValid()"
          mat-button (click)="confirm()">Bestätigen</button>
      </div>
    </form>
  </div>
</section>

<section *ngIf="changedPw" class="password-changed-window-wrapper">
  <div class="password-changed-window">
    <form #userForm="ngForm">
      <p class="mat-form-field-passwordChanged">Das Passwort wurde gerändert</p>
      <div class="spacer-wrapper">
        <button color="primary" class="button-small button-confirm primary" mat-button (click)="confirmChangedPw()">Ok</button>
      </div>
    </form>
  </div>
</section>
