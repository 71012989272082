import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule, routingComponents } from "./app-routing.module";
import { ValidationService } from "./validation.service";
import { MsalService } from "./services/authentication/msal.service";
import { AuthGuardService } from "./services/authentication/auth-gard.service";
import { AuthService } from "./services/authentication/auth.service";
import { BackendService } from "./services/backend/backend.service";
import { AppDateAdapter } from "./services/dateAdapter/date.adapter";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";

registerLocaleData(localeDe, "de");

import { PdfViewerModule } from "ng2-pdf-viewer";
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatToolbarModule,
  MatSelectModule,
  MAT_DATE_LOCALE,
  MatSnackBar,
  MatRadioModule,
  MatCheckbox,
  MatCheckboxModule,
} from "@angular/material";

import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import { LoadingScreenComponent } from "./loading-screen/loading-screen.component";
import { LoadingScreenInterceptor } from "./loading-screen-interceptor/loading-screen-interceptor.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingScreenService } from "./services/loading-screen/loading-screen.service";
import { ResetPasswordComponent } from "./pages/authentication/reset-password/reset-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { DirectivesModule } from "./directives/directives.module";
import { PipeModule } from "./pipes/pipe.module";
import { MatSnackBarModule } from "@angular/material";
import { GlobalErrorHandlerService } from "./services/error/global-error-handler.service";
import { HttpHeaderInterceptor } from "./services/interceptor/http-header.interceptor";
import { AgreementPanelComponent } from "./agreement-panel/agreement-panel/agreement-panel.component";
import { DocumentPanelComponent } from "./document-panel/document-panel.component";
import { MessagePanelComponent } from "./message-panel/message-panel.component";
import { NewMessageComponent } from "./new-message/new-message.component";
import { SelectAgreementComponent } from "./new-message/select-agreement/select-agreement.component";
import { SelectMessageTypeComponent } from "./new-message/select-message-type/select-message-type/select-message-type.component";
import { CreateMessageFormComponent } from "./new-message/create-message-form/create-message-form.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { FileUploadComponent } from "./message-panel/file-upload/file-upload.component";
import { FileUploadDialogComponent } from "./message-panel/file-upload-dialog/file-upload-dialog.component";
import { ContactPanelComponent } from "./contact-panel/contact-panel.component";
import { FormlyWrapperAddons } from "./new-message/create-message-form/addons.wrapper";
import { addonsExtension } from "./new-message/create-message-form/addons.extension";
import { RepliesComponent } from "./message-panel/replies/replies.component";
import { MessageHistoryComponent } from "./message-panel/message-history/message-history.component";
import { ThumbnailBoxComponent } from './message-panel/thumbnail-box/thumbnail-box.component';
import { FileViewComponent } from './pages/file-view/file-view.component';
import { AttachmentComponent } from './pages/attachment/attachment.component';
import { TimeInputComponent } from './time-input/time-input.component';

@NgModule({
  declarations: [	
    AppComponent,
    routingComponents,
    LoadingScreenComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    AgreementPanelComponent,
    DocumentPanelComponent,
    MessagePanelComponent,
    NewMessageComponent,
    SelectAgreementComponent,
    SelectMessageTypeComponent,
    CreateMessageFormComponent,
    FileUploadComponent,
    FileUploadDialogComponent,
    ContactPanelComponent,
    FormlyWrapperAddons,
    RepliesComponent,
    MessageHistoryComponent,
    ThumbnailBoxComponent,
    FileViewComponent,
    AttachmentComponent,
    TimeInputComponent
   ],

  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    PdfViewerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxSpinnerModule,
    DirectivesModule,
    PipeModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'addons', component: FormlyWrapperAddons },
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      types: [
        {
          name: 'time-input',
          component: TimeInputComponent,
          wrappers: ['form-field'],
        },
      ],
      validationMessages: [
        { 
          name: 'invalidTimeFormat', 
          message: 'Die Eingabe muss dem HH.mm Format entsprechen!'
        }
      ],
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    MsalService,
    AuthGuardService,
    AuthService,
    ValidationService,
    BackendService,
    MatDatepickerModule,
    ,
    AppDateAdapter,
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    LoadingScreenService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    LoadingScreenService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
