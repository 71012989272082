import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { MsalService } from 'src/app/services/authentication/msal.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) 
  {

  }  
  
  canActivate(): boolean {
    if (!this.auth.isLoggedIn()) {
        this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  canDeactivate(): boolean {
    return !this.canActivate();
  }
}
