import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../services/dateAdapter/date.adapter';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerInputEvent } from 'saturn-datepicker'
import { ValidationService } from 'src/app/validation.service';
import { Document } from 'src/app/model/Document';
import { DocumentSearchFilter } from 'src/app/model/DocumentSearchFilter';
import { DocumentService } from 'src/app/services/document/document-service.service';

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [ // Formatierung der Interpretation des Datumssuchfilters für Deutschland
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class DocumentsComponent {

  public allDocuments: Document[];
  public documents: Document[];
  // Speicher für die Sucheingaben
  public documentSearchFilter: DocumentSearchFilter;
  public filterCategories: Array<string>;
  public filterContractNumbers: Array<string>;
  filterDisabled: boolean = false;

  // dateRange zur Initialisierung des Filters im DOM
  dateRange;

  constructor(private router: Router, public validationService: ValidationService, public documentService: DocumentService) {
    this.loadFilters();
  }

  async ngOnInit() {
    this.documentService.getDocuments().subscribe(documents => {
      this.documents = documents;
      this.allDocuments = documents;
      this.sortDocuments();
      this.createFilterCategories();
      this.createFilterContractNumbers();
    });
  }

  loadFilters(){
    this.documentSearchFilter = this.documentService.getDocumentSearchFilter();
  }

  setFilters(){
    if(this.documentSearchFilter.agreementInput){
      this.agreement(this.documentSearchFilter.agreementInput);
      this.filterDisabled = this.documentSearchFilter.agreementFilterLocked;
    }

    if(this.documentSearchFilter.criteriaInput){
      this.criteria(this.documentSearchFilter.criteriaInput);
    }

    if(this.documentSearchFilter.searchInput){
      this.find(this.documentSearchFilter.searchInput);
    }

    if(this.documentSearchFilter.dateInputStart) {
      this.dateRange = {'begin': Date, 'end': Date}
      this.dateRange.begin = this.documentSearchFilter.dateInputStart;
      this.dateRange.end = this.documentSearchFilter.dateInputEnd;
    }
  }

  sortDocuments(){
    this.documents = this.documents.sort((n, m) => {
      if (n.typedDate < m.typedDate) {
        return 1;
      }
      else if (n.typedDate === m.typedDate) {
        return 0;
      }
      else {
        return -1;
      }
    });
  }

  createFilterCategories() {
    this.filterCategories = new Array<string>();

    for (var value of this.allDocuments) {
      if (!this.filterCategories.includes(value.category)) {
        this.filterCategories.push(value.category);
      }
    }
  }

  createFilterContractNumbers() {
    this.filterContractNumbers = new Array<string>();

    for (var value of this.allDocuments) {
      if (!this.filterContractNumbers.includes(value.contractSelectField)) {
        this.filterContractNumbers.push(value.contractSelectField);
      }
    }
  }

  ngOnDestroy(){
    if(!(this.router.url.match('document'))) {
      this.documentService.initDocumentSearchFilters();
    }
  }

  ngAfterViewChecked() {
      this.setFilters();
      this.evaluate();
  }

  // evaluiert die Suchausgabe bei Änderung des Freitextfeldes
  find(input: string) {
    if(input || input == ''){
      this.documentSearchFilter.searchInput = input;
      this.evaluate();
    }
  }

  deleteFind(){
    this.documentSearchFilter.searchInput = null;
    this.evaluate;
  }

  // evaluiert die Suchausgabe bei Änderung des Datumsfeldes
  date(input: SatDatepickerInputEvent<any>) {
    if (input.value != null) {
      this.documentSearchFilter.dateInputStart = input.value.begin;
      this.documentSearchFilter.dateInputEnd = input.value.end;
    } else {
      this.deleteDocumentSearchFilter();
    }

    this.evaluate();
  }

  deleteDocumentSearchFilter() {
    this.documentSearchFilter.dateInputStart = null;
    this.documentSearchFilter.dateInputEnd = null;
  }

  deleteDate(){
    this.deleteDocumentSearchFilter();
    this.dateRange.begin = null;
    this.dateRange.end = null;
    this.evaluate;
  }

  getSearchInputForFilter(): string {
    return this.documentSearchFilter.searchInput ? this.documentSearchFilter.searchInput : "";
  }

  // evaluiert die Suchausgabe bei Änderung des Kriteriumsfeldes
  criteria(input: string) {
    if(input){
      this.documentSearchFilter.criteriaInput = input;
      this.evaluate();
    }
  }

  // evaluiert die Suchausgabe bei Änderung des Vertragsnummernfeldes
  agreement(input: string) {
    if(input){
      this.documentSearchFilter.agreementInput = input;
      this.evaluate();
    }
  }

  // schränkt die Anzeige von Dokumenten je nach Sucheingabe ein
  evaluate() {
    this.documents = this.allDocuments;

    // Freitextfeld
    if(this.documentSearchFilter.searchInput) {
      this.documents = this.documents?.filter(res => {
        return res.info1.toLowerCase().match(this.documentSearchFilter.searchInput.toLowerCase())
        || res.info2.toLowerCase().match(this.documentSearchFilter.searchInput.toLowerCase())
        || res.info3.toLowerCase().match(this.documentSearchFilter.searchInput.toLowerCase())
        || res.info4.toLowerCase().match(this.documentSearchFilter.searchInput.toLowerCase());
      });
    }

    // Datumsfeld
    if (this.documentSearchFilter.dateInputStart && this.documentSearchFilter.dateInputEnd) {
      this.documents = this.documents?.filter(res => {
        return res.typedDate >= this.documentSearchFilter.dateInputStart
        && res.typedDate <= this.documentSearchFilter.dateInputEnd;
      });
    }

    // Kriteriumsfeld
    if (this.documentSearchFilter.criteriaInput) {
      this.documents = this.documents?.filter(res => {
        return res.category.toLowerCase().match(this.documentSearchFilter.criteriaInput.toLowerCase());
      });
    }

    // Vertragsfeld
    if (this.documentSearchFilter.agreementInput) {
      this.documents = this.documents?.filter(res => {
        return res.contractSelectField.toLowerCase().match(this.documentSearchFilter.agreementInput.toLowerCase());
      });
    }
  }
}
