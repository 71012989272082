import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageAttachment, MimeType } from 'src/app/model/MessageAttachment';
import { AttachmentsService } from 'src/app/services/attachments/attachments.service';

@Component({
  selector: 'app-thumbnail-box',
  templateUrl: './thumbnail-box.component.html',
  styleUrls: ['./thumbnail-box.component.scss']
})
export class ThumbnailBoxComponent implements OnInit {

  @Input() messageAttachment: MessageAttachment;

  thumbnailUrl: SafeResourceUrl | null = null;
  image;
  MimeType = MimeType;


  constructor(private sanitizer: DomSanitizer, private attachmentService: AttachmentsService, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.renderThumbnail();
  }

  renderThumbnail() {
    if (this.messageAttachment.file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Sanitize and set the image URL
        this.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(e.target.result as string);
      };
      reader.readAsDataURL(this.messageAttachment.file);
    }
  }

  triggerDownload(){
    this.attachmentService.setAttachmentToOpen(this.messageAttachment);
    this.router.navigateByUrl('/attachment');
  }
}
