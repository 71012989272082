import { Message } from "./Message";

export class Contract {
  contractId: string;
  number: string;
  start: string;
  end: string;
  info1: string;
  info2: string;
  info3: string;
  info4: string;
  rent: string;
  unitArea: string;
  unitStreetAndNo: string;
  unitPostcode: string;
  unitCity: string;
  unitInfo1: string;
  unitInfo2: string;
  unitInfo3: string;

  messages: Array<Message>;

  contactPersons: [
    {
      contactPersonID: string;
      role: string;
      salutation: string;
      phone: string;
      mobile: string;
      mail: string;
      company: string;
      streetAndNo: string;
      postcode: string;
      city: string;
    }
  ];

  contactPersonsMax: [
    {
      contactPersonID: string;
      role: string;
      salutation: string;
      phone: string;
      mobile: string;
      mail: string;
      company: string;
      streetAndNo: string;
      postcode: string;
      city: string;
    }
  ];

  documentAmmount: {
    documentCount: string;
    documentCountUnread: string;
  };
}
