/**
 * HTTP-Response-Codes
 * @see {@link https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html}
 *
 * TS-Enum containing HTTP-Response-Codes that are used in this project.
 */

export enum httpStatusCode {

  // 200er Codes:
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,

  // 400er Codes:
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,

  //500er Codes:
  SERVER_ERROR = 500,

}
