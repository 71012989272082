import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import data from '../../../assets/data/config.json';
import { ValidationService } from 'src/app/validation.service.js';

@Injectable()
export class MsalService {

    public configContainer: string[];

    private signup: string;
    private signin: string;
    public guid: string = '';
    private clientApplication: Msal.UserAgentApplication;

     tenantConfig = {
        auth: {
            clientId: "",
            authority: "",
            webApi: '',
            validateAuthority: false,
            navigateToLoginRequestUrl: false,
            scopes: ['openid','offline_access']
        }
    };

    constructor(private router: Router, private http: HttpClient, private validationService: ValidationService){
        this.tenantConfig.auth.authority = this.signin;
        this.tenantConfig.auth.clientId = data[0].clientId;
        this.tenantConfig.auth.authority = data[0].authority;
        this.tenantConfig.auth.webApi = data[0].webApi;

        this.clientApplication = new Msal.UserAgentApplication(this.tenantConfig);
        this.clientApplication.authority = this.tenantConfig.auth.authority;
        this.clientApplication.handleRedirectCallback(this.authCallback.bind(this));

        this.signup = data[0].signup;
        this.signin = data[0].signin;
    }

    private authCallback(error, response) {
        this.router.navigate(['homepage']);
    }

    public authenticate(): void {
        // this.clientApplication.authority = this.signin;
        // this.clientApplication.loginRedirect();
    }

    public logout(): void {
        this.clientApplication.logout();
    };

    public isLoggedIn(): boolean {
        return this.clientApplication.getAccount() != null;
    };

    public registerUser(): void {
        this.clientApplication.authority = this.signup;
        this.clientApplication.loginRedirect();
    }
}
