import { Component } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ValidationService } from 'src/app/validation.service';
import { Contract } from 'src/app/model/Contract';

@Component({
  selector: 'agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent {

  constructor(private backendService: BackendService, public validationService: ValidationService) { }
  public contracts: Contract[] = null;

  ngOnInit() {
    this.backendService.getUserContracts(this.validationService.getUserToken().supreamGuid).subscribe(data => {
      this.contracts = JSON.parse(data);
      this.createContactPersonsToDisplay();
    });
  }

  createContactPersonsToDisplay() {
    for (var contract of this.contracts) {
      for (var person of contract.contactPersons) {

        if (!contract.contactPersonsMax) {
          contract.contactPersonsMax = [person];
        }

        if (!contract.contactPersonsMax.includes(person) && contract.contactPersonsMax.length < 2) {
          contract.contactPersonsMax.push(person);
        }
      }
    }
  }
}
